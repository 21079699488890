import React from "react";

const PandCsharingThisInfo = () => {
  return (
    <>
      Certain services available on this Site are offered in conjunction with
      one or more of our business partners. For those services to be provided we
      provide some necessary details about you to our business partners. We tell
      you about this at the point we collect that information on the Site.
      Please note that certain services may be unavailable if you do not want to
      disclose the personal information you are asked for. We may transfer your
      personal information to a third party as part of a sale of some or all of
      our business and assets to any third party or as part of any business
      restructuring or reorganization, but we will take steps with the aim of
      ensuring that your privacy rights continue to be protected.
      <br />
      In addition, we may pass your information onto one of our carefully
      selected business partners or to other carefully selected third parties to
      enable them to send you information which may be of interest to you but
      only if you have permitted us to do so. You can tell us to stop this at
      any time by sending an email. Other than as set out above, we will not
      disclose any of your personal information without your permission unless
      we are required by law to do so (for example, if required to do so by a
      court order or for prevention of fraud or other crime).
    </>
  );
};

export default PandCsharingThisInfo;
