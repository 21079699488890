import Down from "../assets/icons/Keyboard arrow down.svg";
import Nga from "../assets/images/NGA.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";
import Cright from "../assets/images/Vector-4.png";
import Cbn from "../assets/images/cbn.png";
import { useHistory } from "react-router-dom";

const Footer = ({ locPath, value, setValue }) => {
  const history = useHistory();

  // const checkHash = () => {
  //   const hash = window.location.hash;

  //   if (hash) {
  //     const id = hash.replace("#", "");
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollIntoView();
  //     }
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // };

  const redirectToUrl = (path) => {
    history.push(path);
    window.scrollTo(0, 0);
    // checkHash();
  };

  // useEffect(() => {
  //   checkHash();
  // }, []);

  return (
    <div className="max-w-[1440px] relative mx-auto z-auto scrollbar-hide [#FFFFFF] px-[152px] lg:px-[70px] md:px-[30px] py-[32px]">
      <div
        className={` w-full px-[0px] flex flex-col lg:w-full md:flex-col ${
          locPath === "/getstarted" ? "hidden" : ""
        } `}
      >
        <div className=" mb-[96px] flex justify-between xl:justify-items-center md:justify-items-start w-full flex-row xl:grid xl:grid-cols-3 xl:auto-rows-auto lg:grid-cols-2  gap-y-[60px]">
          <div className=" space-y-[24px] md:space-y-[12px]">
            <h4 className="font-[Sarabun] sm:text-[14px] font-semibold text-[#000000] text-[16px] leading-[24px] tracking-[0.02em] ">
              ABOUT US
            </h4>
            <li
              onClick={() => redirectToUrl("/about")}
              className="font-[Sarabun] cursor-pointer sm:text-[14px] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em] "
            >
              Overview
            </li>
            <li
              onClick={() => redirectToUrl("/")}
              className="font-[Sarabun] cursor-pointer sm:text-[14px] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em] "
            >
              <a onClick={() => redirectToUrl("/")} href="#brand">
                Our brands
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  redirectToUrl("/about");
                  setValue(2);
                }}
                href="#vision"
                className="font-[Sarabun] cursor-pointer sm:text-[14px] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em] "
              >
                Social responsibility
              </a>
            </li>
            <li
              onClick={() => redirectToUrl("/aboutcareers")}
              className="font-[Sarabun] cursor-pointer sm:text-[14px] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em] "
            >
              Careers
            </li>
            <li
              onClick={() => redirectToUrl("/partners")}
              className="font-[Sarabun] cursor-pointer sm:text-[14px] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em] "
            >
              Partners
            </li>
          </div>
          <div className=" space-y-[24px] md:space-y-[12px]">
            <h4 className="font-[sarabun] font-semibold text-[#1E2C24] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              OUR PRODUCTS
            </h4>
            <li
              onClick={() => redirectToUrl("/products")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em]  "
            >
              Payment gateway
            </li>
            <li
              onClick={() => redirectToUrl("/checkout")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              Checkout
            </li>
            <li
              onClick={() => redirectToUrl("/mobileapp")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              Mobile App
            </li>
            <li
              onClick={() => redirectToUrl("/pos")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              POS
            </li>
            <li
              onClick={() => redirectToUrl("/collection")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              Collect platform
            </li>
          </div>
          <div className=" space-y-[24px] md:space-y-[12px]">
            <h4 className="font-sarabun font-semibold text-[#1E2C24] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              LEGAL
            </h4>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              <a
                onClick={() => {
                  redirectToUrl("/privacycookies");
                }}
                href="#terms"
              >
                Terms and Conditions
              </a>
            </li>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              <a
                onClick={() => redirectToUrl("/privacycookies")}
                href="#privacy"
              >
                Privacy Policy
              </a>
            </li>

            <li
              onClick={() => redirectToUrl("/privacycookies")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              <a
                onClick={() => redirectToUrl("/privacycookies")}
                href="#cookies"
              >
                Cookies Policy
              </a>
            </li>

            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              <a onClick={() => redirectToUrl("/privacycookies")} href="#terms">
                Merchant Service Agreement
              </a>
            </li>
            <li
              onClick={() => redirectToUrl("/pricing")}
              className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
            >
              Pricing
            </li>
          </div>
          <div className=" space-y-[24px] md:space-y-[12px]">
            <h4 className="font-[sarabun] font-semibold text-[#1E2C24] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em]  ">
              DEVELOPERS
            </h4>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              API Documentation
            </li>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              API Reference
            </li>
          </div>

          <div className="col-span-2 space-y-[24px] md:space-y-[12px]">
            <h4 className="font-[sarabun] font-semibold text-[#1E2C24] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              CONTACT
            </h4>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              <a href="mailto:hello@sayswitchgroup.com">
                hello@sayswitchgroup.com
              </a>
            </li>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              Twitter Support
            </li>
            <li className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
            07066032484
            </li>
          </div>
        </div>
        <div className=" w-full py-[30px] flex flex-row justify-between items-center border-t border-b border-b-slate-200 ">
          <div className="flex items-center gap-2 ">
            <img src={Nga} alt="" className="w-[20px] h-[18.76px] block" />
            <p className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              Nigeria
            </p>
            <span>
              <img
                src={Down}
                alt=""
                className="w-[22px] text-[0.02em] inline"
              />
            </span>
          </div>
          <div className="flex items-center gap-[24px] md:gap-[6px]">
            <img src={facebook} alt="" className="md:w-[20px] md:h-[20px]" />
            <img src={twitter} alt="" className="md:w-[20px] md:h-[20px]" />
            <img src={instagram} alt="" className="md:w-[20px] md:h-[20px]" />
            <img src={linkedin} alt="" className="md:w-[20px] md:h-[20px]" />
          </div>
        </div>
        <div className=" w-full py-[30px] flex flex-row justify-between items-center border-t border-b border-b-slate-200 ">
          <div className="flex items-center gap-2 ">
            <img src={Cright} alt="" className="w-[20px] h-[18.76px] block" />
            <p className="font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] ">
              Sayswitch
            </p>
          </div>
          <div className="flex items-center gap-[24px] md:gap-[6px]">
          <span>
            {/* <img src={Cright} alt="" /> */}
          </span>
          <li className="text-left font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[12px] leading-[24px] tracking-[0.02em] ">
      
          </li>
          </div>
        </div>
      </div>
      <div className=" pt-[34px] flex items-center justify-between gap-[6px]">
        <div className="  flex items-center gap-[6px]">
          <span>
            <img src={Cbn} width="30px" height="50px" alt="" />
          </span>
          <li className="text-left font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[18px] leading-[24px] tracking-[0.02em] ">
          Interswitch is regulated by the Central Bank of Nigeria
          </li>
        </div>
        <div className="  flex items-center gap-[6px]">
      
        </div>
        <div className="flex items-center gap-[20px] md:hidden">
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#disclaimer"
            className="text-left font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
          >
            Disclaimer
          </a>
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#isopolicy"
            className="text-left font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
          >
            ISO Policy
          </a>
          <a
            onClick={() => {
              redirectToUrl("/privacycookies");
            }}
            href="#trademark"
            className="text-left font-[Sarabun] font-normal cursor-pointer text-[#3B443F] text-[16px] sm:text-[14px] leading-[24px] tracking-[0.02em] "
          >
            Trademark License
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
