import React, { useState } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import HomeView from "./View/HomeView";
import PartnersView from "./View/PartnersView";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProductsView from "./View/ProductsView";
import Overlay0ne from "./components/Overlay0ne";
import OverlayTwo from "./components/OverlayTwo";
import CheckoutView from "./View/CheckoutView";
import AboutView from "./View/AboutView";
import AboutCareerView from "./View/AboutCareerView";
import MobileAppView from "./View/MobileAppView";
import SayswitchPOS from "./components/SayswitchPOS";
import AgentBanking from "./components/AgentBanking";
import Pricing from "./components/Pricing";
import SayswitchAndProcessing from "./components/SayswitchAndProcessing";
import CollectionAndDisb from "./components/CollectionAndDisb";
import ContactUs from "./components/ContactUs";
import PrivacyAndCookies from "./components/PrivacyAndCookies";

function App() {
  const [isLabel, setIsLabel] = useState("");
  const Location = useLocation();
  const locPath = Location.pathname;
  const [value, setValue] = useState(1);

  return (
    <>
      <div className="relative ">
        <Navbar isLabel={isLabel} setIsLabel={setIsLabel} locPath={locPath} />
        <Switch>
          <Route exact path="/">
            <HomeView />
          </Route>
          <Route exact path="/mobileapp">
            <MobileAppView />
          </Route>
          <Route exact path="/products">
            <ProductsView />
          </Route>
          <Route exact path="/about">
            <AboutView value={value} setValue={setValue} />
          </Route>
          <Route exact path="/pos">
            <SayswitchPOS />
          </Route>
          <Route exact path="/agentbanking">
            <AgentBanking />
          </Route>
          <Route exact path="/partners">
            <PartnersView />
          </Route>
          <Route exact path="/aboutcareers">
            <AboutCareerView />
          </Route>
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/contactus">
            <ContactUs />
          </Route>
          <Route exact path="/sayprocessing">
            <SayswitchAndProcessing />
          </Route>
          <Route exact path="/privacycookies">
            <PrivacyAndCookies />
          </Route>
          {/* <Route exact path="/getstarted">
            <GetStarted />
          </Route> */}
          <Route exact path="/checkout">
            <CheckoutView />
          </Route>
          <Route exact path="/collection">
            <CollectionAndDisb />
          </Route>
          <Redirect path="*" to="/" />
        </Switch>
        <Footer locPath={locPath} value={value} setValue={setValue} />
        {isLabel === "about" ? (
          <div
            onClick={() => setIsLabel("")}
            className="absolute top-[0px] lg:hidden  left-0 bg-green-600/30 backdrop-blur-sm w-full h-[100%] z-[1000]"
          >
            <Overlay0ne />
          </div>
        ) : isLabel === "products" ? (
          <div
            onClick={() => setIsLabel("")}
            className="absolute top-[0px] lg:hidden left-0 bg-green-600/30 backdrop-blur-sm w-full h-[100%] z-[1000]"
          >
            <OverlayTwo />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default App;
