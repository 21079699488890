import React from "react";
import { Link } from "react-router-dom";
import number from "../assets/icons/Numbers.png";
import Button from "./Button";

const GlobalAcceptance = () => {
  return (
    <div className="w-full flex-col justify-start px-[152px] lg:px-[70px] md:px-[30px] py-[156px] bg-[#010510]">
      <h1 className="mb-[59px] text-green-300 font-[sarabun] sm:text-[50px] sm:leading-[60px] font-bold text-[60px] leading-[72px]">
        Global Acceptance and all
        <br />
        <span className="text-[#ffffff]">popular payment methods</span>
      </h1>
      <div className="flex justify-start">
        <Link to="/contactus">
          <Button className="bg-[#008037] text-[#ffffff] border-none w-[234px] h-[56px]">
            Contact Sales
          </Button>
        </Link>
      </div>

      <ul className="w-1/3 mt-[102px] lg:w-1/2 md:w-full flex flex-col gap-[60px]">
        <ul>
          <ul className="list-inside">
            <li className="text-[#7FBD90]">
              <img
                src={number}
                alt=""
                width="16px"
                height="16px"
                className="inline"
              />{" "}
              Expand into new markets
            </li>
          </ul>
          <li className="text-[#ffffff] pl-[20px]">
            We offer payment methods customers expect
          </li>
        </ul>
        <ul>
          <ul className="list-inside">
            <li className="text-[#7FBD90] ">
              <img
                src={number}
                alt=""
                width="16px"
                height="16px"
                className="inline"
              />{" "}
              Simple payments
            </li>
          </ul>
          <li className="text-[#ffffff] pl-[20px]">
            Accept, process, and settle payments with one platform.
          </li>
        </ul>
        <ul>
          <ul className="list-inside">
            <li className="text-[#7FBD90] ">
              <img
                src={number}
                alt=""
                width="16px"
                height="16px"
                className="inline"
              />{" "}
              Beautiful, intuitive dashboard
            </li>
          </ul>
          <li className="text-[#ffffff] pl-[20px]">
            Improved approval rate by issuers
          </li>
        </ul>
        <ul>
          <ul className="list-inside">
            <li className="text-[#7FBD90] ">
              <img
                src={number}
                alt=""
                width="16px"
                height="16px"
                className="inline"
              />{" "}
              One integretion
            </li>
          </ul>
          <li className="text-[#ffffff] pl-[20px]">
            Connect to global payment providers and acquirers with one
            integration
          </li>
        </ul>
        <ul>
          <ul className="list-inside">
            <li className="text-[#7FBD90] ">
              <img
                src={number}
                alt=""
                width="16px"
                height="16px"
                className="inline"
              />{" "}
              Fullly certified, standards compliant
            </li>
          </ul>
          <li className="text-[#ffffff] pl-[20px]">
            Payments within secured environment that align with industry
            standards - PCIDSS, ISO 20000, ISO 23001, ISO 27001
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default GlobalAcceptance;
