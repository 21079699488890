import React from "react";

const PandCcardNetworkRules = () => {
  return (
    <>
      Each card network has its own rules, regulations and guidelines. You are
      required to comply with all applicable Network Rules that are applicable
      to merchants. You can review portions of the Network Rules at Mastercard,
      Visa, Verve and other payment cards. The Card Networks reserve the right
      to amend the Network Rules.
    </>
  );
};

export default PandCcardNetworkRules;
