import React from "react";
import icon from "../assets/icons/ICONSpass.png";
import pos from "../assets/images/POS TERM remove bg 2.png";

const FullyIntegrated = () => {
  return (
    <div className=" w-full ">
      <div className="w-[1130px] mx-auto lg:mx-[0] xl:w-full flex flex-col items-center lg:items-start  lg:gap-[24px]  px-[158px] lg:px-[70px] md:px-[30px]  pt-[120px] lg:pt-[0px] rounded-[20px] lg:rounded-[0] mt-[-256px] lg:mt-[0] bg-[#ffffff]">
        <h3 className="mb-[32px]  font-[sarabun] font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[-0.02em]">
          Fully integrated hardware
        </h3>
        <p className="font-[sarabun] lg:text-left font-normal text-center text-[#3B443F] text-[18px] leading-[26px] tracking-[0.02em]">
          Sayswitch POS terminal application software is highly robust with
          extreme flexibility that allows for any customized type of transaction
          both traditional and digital that might be required by the merchant or
          acquirer. Some of its unique features include:
        </p>
        <div className="lg:mb-[24px] w-full flex lg:flex-col-reverse mt-[25px]">
          <div className="w-1/2 lg:w-full flex flex-col justify-center">
            <ul className="flex flex-col gap-[24px] font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
              <li className="flex gap-[14px]">
                <span>
                  <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
                </span>
                {""}
                Multi EMV card support
              </li>
              <li className="flex gap-[14px]">
                <span>
                  <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
                </span>
                {""}
                Remote software download
              </li>
              <li className="flex gap-[14px]">
                <span>
                  <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
                </span>
                {""}
                Security (Anti-cloning of terminal ID)
              </li>
              <li className="flex gap-[14px]">
                <span>
                  <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
                </span>
                {""}
                Unique status reporting support module that sends a report of;
              </li>
              <ul className="ml-[60px] mt-[-24px]">
                <li className="list-disc">Battery status</li>
                <li className="list-disc">power connectivity</li>
                <li className="list-disc">paper jam</li>
                <li className="list-disc">
                  GPRS connectivity status and location
                </li>
                <li className="list-disc">Active / Inactive terminal status</li>
              </ul>
            </ul>
          </div>
          <div className="w-1/2 lg:w-full  flex items-end">
            <img src={pos} alt="" width="535.2px" height="409.3px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullyIntegrated;
