import React from "react";
import Frame from "../assets/images/Vector 1.png";
import Photo from "../assets/images/Dashboard 5.png";
import icon from "../assets/icons/ICONS-2.png";

function Insights() {
  return (
    <div className="w-full px-[152px] pt-[152px] lg:pt-[0px] bg-[#ffffff] flex flex-col gap-[90px] md:gap-[0px] lg:px-[40px] md:px-[0px] ">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Insights
          </p>
        </div>
        <div>
          <h1 className="font-[Sarabun] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            Designed for easy
            <br /> managerial support
          </h1>
        </div>
      </div>
      <div
        className="container  space-y-8 flex 
     md:p-[30px] md:mt-[-20px] gap-12 flex-row 
     md:flex-col-reverse md:items-center "
      >
        <div className="w-[40%] md:w-full my-auto space-y-[32px]">
          <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
            Take full control of your business, track expenses, analyse and
            optimize every step of your payment flow
          </p>
          <ul className="flex flex-col gap-[24px] font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
            <li className="flex gap-[14px]">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Beautiful, intuitive dashboard
            </li>
            <li className="flex gap-[14px]">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Track inflow and outflow of money over time
            </li>
            <li className="flex gap-[14px]">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Full account view in your dashboard
            </li>
          </ul>
        </div>
        <div className="w-[60%] md:w-full block">
          <img
            src={Photo}
            alt="pic"
            width="960.25px"
            height="610px"
            className="w-full "
          />
        </div>
      </div>
    </div>
  );
}

export default Insights;
