import React from "react";
import SimplePayAsYouGo from "./SimplePayAsYouGo";
import PricingCards from "./PricingCards";
import ReadyToStart from "./ReadyToStart";
import DoTheMathSection from "./FeeCalculator";

const Pricing = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide xl:top-[96px] lg:top-[64px] left-[0]">
      <SimplePayAsYouGo />
      <PricingCards />
      {/* <DoTheMathSection /> */}
      {/* <FrequentlyAskedQuestion /> */}
      <ReadyToStart />
    </div>
  );
};

export default Pricing;
