import React from "react";
import CardSchemeGateway from "./CardSchemeGateway";
import IssuerSolution from "./IssuerSolution";
import ReadyToStart from "./ReadyToStart";
import SupportingBusiness from "./SupportingBusiness";
import ValueAddedService from "./ValueAddedService";

const SayswitchAndProcessing = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <SupportingBusiness />
      <IssuerSolution />
      <CardSchemeGateway />
      <ValueAddedService />
      <ReadyToStart />
    </div>
  );
};

export default SayswitchAndProcessing;
