import React, { useState } from "react";
import { PandCList } from "./P&CList";
import Backtop from "../assets/icons/backtoTop.svg";

import PandCsideBar from "./PandCsideBar";

function PrivacyAndCookies() {
  const [active, setActive] = useState(1);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400) {
      setVisible(true);
    } else if ((scrolled) => 400) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="max-w-[1440px] mt-[40px] md:mt-[5px] relative mb-[100px] m-auto  xl:top-[96px] lg:top-[64px] left-[0] flex px-[152px]  lg:px-[70px] md:px-[30px]">
      <div className="basis-1/4 ">
        <PandCsideBar active={active} setActive={setActive} />
      </div>
      <div className="overflow-auto overscroll-contain flex-col mx-[80px] md:ml-[10px] md:mr-[0px] items-center basis-3/4 ">
        <h1 className="mb-[32px] font-[sarabun] font-bold  lg:text-[22px] md:text-[20px]    text-[#1E2C24] text-[28px] leading-[36px] tracking-[0.02em]">
          Privacy and Cookie Policy
        </h1>
        <div>
          {PandCList.map(({ title, id, content }) => (
            <div key={id} className={"flex flex-col gap-[16px] "}>
              <h1
                id={title}
                className="font-[sarabun] capitalize font-bold text-[#1E2C24] lg:text-[18px] sm:text-[15px] text-[22px] leading-[30px] tracking-wide "
              >
                {title}
              </h1>
              <div className="mb-[48px] text-justify font-sarabunlight md:text-[14px] sm:text-sm text-[16px] leading-[27px] tracking-wide ">
                {content}
              </div>
            </div>
          ))}
        </div>

        <img
          src={Backtop}
          alt=""
          width="80px"
          height="80px"
          className={` ${
            visible
              ? "fixed top-3/4 right-[50px] lg:right-[20px] select-none shadow-lg  cursor-pointer active:bg-black-300 rounded-lg  font-sarabunlight text-bold z-[10000] sm:text-sm text-black-900 visible md:p-[5px] p-[16px]"
              : "hidden"
          } `}
          onClick={() => {
            scrollToTop();
            toggleVisible();
            setActive(1);
          }}
        />
      </div>
    </div>
  );
}

export default PrivacyAndCookies;
