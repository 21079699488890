import React from "react";
import photo2 from "../assets/images/software-developer.png";
import icon from "../assets/icons/ICON-d.png";

const SayswitchApi = () => {
  return (
    <div className="w-full flex pb-[138px] pt-[259px] clipath bg-[#151917] lg:mb-[0px] lg:flex-col-reverse pl-[152px] xl:pl-[0px]:xl:px-[152px] lg:px-[70px] md:px-[30px]">
      <div className="w-1/2 my-auto lg:w-full pr-[15%] xl:pr-[10%] lg:pr-[0px] flex flex-col items-start ">
        <h3 className="text-[#FFE874] lg:mt-[40px] font-sarabun font-bold text-[18px] leading-[26px] tracking-[0.02em]">
          Designed for developers
        </h3>
        <h1 className="font-[sarabun] mt-[24px] text-[#ffffff] text-left font-bold text-[48px] leading-[56px]  ">
          Sayswitch APIs
          <br /> power innovation
        </h1>
        <p className="font-[sarabun] mt-[32px] text-[#BBC4BF] text-left text-[16px] leading-[24px] tracking-[0.002em]">
          Build and deploy globally scalable payment acceptance and disbursement
          from a single platform.
        </p>
        <div className="mt-[40px] lg:mb-[40px] text-[#ffffff] font-sarabun font-bold text-[18px] leading-[26px] tracking-[0.02em] flex justify-start gap-[24px] md:gap-3 md:flex-col">
          <div>
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Robust API
            </li>
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Global Reach
            </li>
          </div>
          <div>
            {" "}
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Built-in Compliance
            </li>
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Detailed Reporting
            </li>
          </div>
        </div>
      </div>
      <div className="w-1/2 lg:w-full flex justify-end lg:justify-center mt-[26px]">
        <img src={photo2} alt="" width="908px" height="578px" />
      </div>
    </div>
  );
};

export default SayswitchApi;
