import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const SimplePayAsYouGo = () => {
  return (
    <div className=" h-[450px] flex flex-col  px-[152px] lg:px-[70px] md:px-[30px] bg-[#151917]">
      <div className="w-full lg:mx-auto mt-[59px] xs:mt-[15px] flex flex-col justify-center items-center md:items-start">
        <h1 className="mb-[32px] md:mb-[10px] text-[#ffffff] font-bold text-[60px] md:text-[40px] text-center md:text-left md:leading-[50px] leading-[72px] tracking-[0.02em]">
          Simple, pay as you go
        </h1>
        <p className="mb-[72px] xs:mb-[10px] px-[200px] lg:px-[0px] text-center md:text-left text-[#BBC4BF] font-normal text-[18px] leading-[26px] tracking-[0.01em] ">
          Our pricing is designed to support your business at any level of
          growth, you see exactly what you pay, nothing hidden, ever.
        </p>
        <div className="flex md:flex-col  gap-[24px] xs:gap-[10px] ">
          <Link to="/contactus">
            <Button className="bg-[#018038] h-[56px] min-w-[239px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
              Contact sales
            </Button> 
          </Link>
          {/* <Link>
            <Button className="bg-[#ffffff] h-[56px] min-w-[239px] flex text-[#008037] justify-center items-center border-none font-bold  p-[20px]">
              Contact sales
            </Button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default SimplePayAsYouGo;
