import React from "react";
import { Link } from "react-router-dom";

const SubMenuTwo = ({ clearHandler }) => {
  return (
    <div
      onClick={clearHandler}
      className=" gap-2 flex flex-col ml-4 rounded-lg transition-[0.2ms]  text-left hover:divide-white divide-slate-200 divide-y"
    >
      <Link to="/about">
        <li className="hover:text-[red] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Overview
        </li>
      </Link>
      <Link to="/aboutcareers">
        <li className="hover:text-[red] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Careers
        </li>
      </Link>
      <Link to="/contactus">
        <li className="hover:text-[red] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Contact Us
        </li>
      </Link>
    </div>
  );
};

export default SubMenuTwo;
