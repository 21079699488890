import React from "react";
import AboutCareers from "../components/AboutCareers";
import JoinOurTeam from "../components/JoinOurTeam";
import WhatWeOffer from "../components/whatWeOffer";

const AboutCareerView = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <AboutCareers />
      <WhatWeOffer />
      <JoinOurTeam />
    </div>
  );
};

export default AboutCareerView;
