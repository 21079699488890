import React from "react";

const PandCHowWeHandleYourFunds = () => {
  return (
    <>
      You authorize and instruct Sayswitch to hold, receive, and disburse funds
      on your behalf when such funds from your card transactions settle from the
      Card Networks. By accepting this Agreement, you further authorize
      Sayswitch on how your card transaction settlement funds should be
      disbursed to you as Payouts and the timing of such Payouts.
      <br />
      <br />
      You agree that you are not entitled to any interest or other compensation
      associated with the settlement funds held by Sayswitch pending settlement
      and Payout to your Bank Account. Settlement funds will be held in a
      deposit account at Sayswitch pending Payouts to you in accordance with the
      terms of this contract. We may periodically make available to you
      information about pending settlements yet to be received from the Card
      Networks. Your authorizations will remain valid and be of full effect
      until your Sayswitch Account is closed or terminated.
    </>
  );
};

export default PandCHowWeHandleYourFunds;
