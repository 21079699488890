import React from "react";
import { PandCList } from "./P&CList";
import { Link } from "react-scroll";

const PandCsideBar = ({ active, setActive }) => {
  return (
    <div className=" w-full sticky top-0 max-h-[100vh] overflow-y-scroll bg-[#F7F7F7]">
      <ul
        className="px-[32px] lg:px-[15px] md:px-[10px] sm:px-[5px] 
      lg:text-sm md:text-xs py-[40px] flex flex-col gap-[16px] font-[sarabun] 
      font-normal text-[#3B443F] text-[16px] leading-[22px] tracking-wide"
      >
        {PandCList.map(({ id, title }) => (
          <li key={id}>
            <Link
              to={title}
              smooth={true}
              offset={-50}
              duration={500}
              onClick={() => {
                setActive(id);
              }}
              className={`cursor-pointer ${
                id === active ? "text-[#008037]" : ""
              }`}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PandCsideBar;
