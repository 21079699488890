import React from "react";
import Button from "./Button";
import phone from "../assets/images/image 9.png";
import { useHistory } from "react-router-dom";

const PaymentInt = () => {
  const history = useHistory();
  return (
    <div>
      <div className="w-full flex  lg:flex:col px-[132px] xl:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
        <div className="w-1/2 md:w-full py-[100px] flex flex-col justify-start align-center">
          <h1 className="mb-[24px] text-[#ffffff] font-bold text-[36px] leading-[44px] tracking-[0.025em]">
            Payment gateway integration
          </h1>
          <p className="mb-[40px] text-[#BBC4BF] font-bold text-[18px] leading-[26px] tracking-[0.01em] ">
            Sayswitch checkout is a secure and modern solution. Easy to
            integrate with your online storefront.
          </p>
          <div
            onClick={() => {
              history.push("/checkout");
              window.scrollTo(0, 0);
            }}
          >
            <Button className="bg-[#018038] cursor-pointer h-[56px] min-w-[229px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
              Learn more{" "}
            </Button>
          </div>
        </div>
        <div className="w-1/2 md:w-full mt-[56px] flex justify-end md:hidden">
          <img
            src={phone}
            alt=""
            className=""
            width="331px"
            height="663.54px"
          />
        </div>
      </div>
    </div>
  );
};
export default PaymentInt;
