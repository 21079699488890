import React from "react";
import icon from "../assets/icons/ICONSpass.png";

const PayContractors = () => {
  return (
    <div className="w-full px-[152px] py-[130px] lg:px-[70px] md:px-[30px] bg-[#F3F6F9] flex flex-row-reverse justify-center lg:gap-y-8 lg:flex-col-reverse rounded-lg">
      <div className=" flex flex-col  justify-center  w-1/2 lg:w-full  ">
        <div className=" pl-[100px] lg:pl-0 font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
          <li className="flex justify-start mt-5 gap-5">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
            </span>
            Disburse funds worldwide with our API or by uploading a CSV.
          </li>
          <li className="flex justify-start mt-5  gap-5">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
            </span>
            Payouts to bank account in Nigeria and some banks in the world
          </li>
          <li className="flex justify-start mt-5  gap-5">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
            </span>
            Payout to one or multitude of recipients across the countries and
            currencies
          </li>
        </div>
      </div>
      <div className=" flex justify-left my-auto w-1/2 lg:w-full ">
        <h1 className="font-[sarabun] font-extrabold text-[#1E2C24] text-[48px] leading-[56px]">
          Pay contractors, suppliers, or any business anywhere in the world.
        </h1>
      </div>
    </div>
  );
};

export default PayContractors;
