import React from "react";

const PandCregistration = () => {
  return (
    <>
      To use the Sayswitch portal, you have to create an Sayswitch account by
      registering. To register, you will provide us with certain information
      such as your email, first name, last name, business name and phone number
      and we may seek to verify your information, (by ourselves or through third
      parties), after which we will approve your account unless deemed risky.
      You give us permission to do all these.
    </>
  );
};

export default PandCregistration;
