import React from "react";

const PandCdataCompliance = () => {
  return (
    <>
      You agree to comply with all data privacy and security requirements of the
      Payment Card Industry Data Security Standard (PCI DSS Requirements”) and
      under any applicable law or regulation that may be in force, enacted or
      adopted regarding confidentiality, your access, use, storage and
      disclosure of user information. Information on the PCI DSS can be found on
      the PCI Council’s Portal. It is your responsibility to comply with these
      standards. We are responsible for the security and protection of Card
      Holder Data (CHD) we collect and store. Accordingly, we implement access
      control measures, security protocols and standards including the use of
      encryption and firewall technologies to ensure that CHD is kept safe and
      secure on our servers, in compliance with the PCI DSS Requirement. We also
      implement periodical security updates to ensure that our security
      infrastructures are in compliance with reasonable industry standards.
      <br />
     <br/>
      We acknowledge that you own all your customers’ data. You hereby grant
      Sayswitch a perpetual, irrevocable, sub-licensable, assignable, worldwide,
      royalty-free license to use, reproduce, electronically distribute, and
      display your customers’ data for the following purposes:
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Providing and improving our services;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Internal usage, including but not limited to, data analytics and
          metrics so long as individual customer data has been anonymized and
          aggregated with other customer data;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Complying with applicable legal requirements and assisting law
          enforcement agencies by responding to requests for the disclosure of
          information in accordance with local laws; and
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Any other purpose for which consent has been provided by your
          customer.
        </li>
      </ol>
    </>
  );
};

export default PandCdataCompliance;
