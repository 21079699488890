import React from "react";
import woman2 from "../assets/images/cropped-shot-beautiful-african-american-woman-with-happy-look-holds-modern-cell-phone-credit-card-makes-shopping-online 1.png";
import icon from "../assets/icons/ICONSpass.png";

const CardSchemeGateway = () => {
  return (
    <div className="bg-[#ffffff] flex flex-col gap-[64px] lg:gap-3  w-full pb-[120px] lg:pb-[0px] pt-[156px] lg:pt-[70px] md:pt-[50px] px-[152px] lg:px-[70px] md:px-[30px] ">
      <div>
        <h1 className=" font-[sarabun] text-[#1E2C24] font-bold text-[36px] leading-[44px] tracking-[0.02em]">
          Card Scheme Gateway Service
        </h1>
        <p className="mt-[24px] pr-[30%] lg:pr-0 font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
          Whether you are an issuer or an acquirer, trust us for your
          frictionless transaction processing. We will look after your
          operations and deal with the compliance mandate, guidelines, and
          requirements of the card scheme so you can focus on your core revenue
          generating businesses.
        </p>
      </div>
      <div className="w-full bg-[#ffffff] flex flex-row-reverse justify-center lg:flex-col rounded-lg">
        <div className=" flex flex-col  justify-center ml-[150px] lg:m-0 py-[56px] lg:py-0 w-1/2 lg:w-full  ">
          <h1 className="mt-[32px] font-[sarabun] text-left  text-[#1E2C24] font-bold text-[18px] leading-[26px] tracking-[0.02em] ">
            Key Features
          </h1>
          <div className="mt-[20px]  font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              High-performance transaction switching service that ensures
              connections to Visa and Mastercard networks.
            </li>
            <li className="flex justify-start mt-5  gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Our gateway service facilitates the dynamic switching and
              processing of a wide range of transaction types originating from
              multiple channels across different payment networks.
            </li>
            <li className="flex justify-start mt-5  gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              We work with you to extend this service to accommodate a variety
              of local networks so you can participate in wider payment
              ecosystems.
            </li>
          </div>
        </div>
        <div className=" flex justify-center py-[56px] w-1/2 lg:w-full ">
          <img
            src={woman2}
            alt=""
            width="582px"
            height="482px"
            className=" rounded-[8px] lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default CardSchemeGateway;
