import React from "react";
import ReadyToStart from "../components/ReadyToStart";
import SeamlessPayment from "../components/SeamlessPayment";

import SecureAndModern from "../components/SecureAndModern";
import ServicesAndAddedValue from "../components/ServicesAndAddedValue";

const CheckoutView = () => {
  return (
    <div className="max-w-[1440px]  m-auto scrollbar-hide relative top-[96px] lg:top-[64px]">
      <SecureAndModern />
      <SeamlessPayment />
      <ServicesAndAddedValue />
      <ReadyToStart />
    </div>
  );
};

export default CheckoutView;
