import React from "react";
import Frame from "../assets/images/Vector 1.png";
import icon from "../assets/icons/ICONSpass.png";
import photo from "../assets/images/big-Dashboard 5.png";
import photo2 from "../assets/images/small-checkout 1.png";

const GlobalPayouts = () => {
  return (
    <div className="w-full px-[152px] py-[156px] flex flex-col gap-[80px] lg:gap-[40px] bg-[#ffffff] lg:px-[70px] md:px-[30px] pb-[156px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center">
          <img src={Frame} alt="" width="41px" className="" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Global payouts
          </p>
        </div>
        <div>
          <h1 className="font-[Sarabun] mb-[32px] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            Disbursement & Collections
          </h1>
          <p className="font-[Sarabun] mx-[10%] lg:mx-[5%] md:mx-0 text-center md:text-left font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            It is a web-based solution for payroll, marketplace sellers and
            other corporate payments. Disbursement is for you if you are:
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row-reverse justify-center gap-x-[28px]  lg:flex-col-reverse rounded-lg">
        <div className=" flex flex-col  justify-start  w-1/2 lg:w-full  ">
          <div className="font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Looking for a solution on a real-time claims’ payout, merchant
              settlement and payroll payment to employees among other use cases.
            </li>
          </div>
        </div>
        <div className=" flex justify-left w-1/2 lg:w-full ">
          <div className="font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Mobile wallet provider seeking to enable wallet payouts and
              remittances
            </li>
            <li className="flex justify-start mt-5  gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Digital bank seeking to facilitate funds transfers or
              disbursements to your consumers
            </li>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center lg:hidden flex-row-reverse">
        <img
          src={photo}
          alt=""
          max-width="1024px"
          max-height="650.5px"
          className=" "
        />
        <img
          src={photo2}
          alt=""
          max-width="288.89px"
          max-height="593.09px"
          className=" mr-[-180px] mb-[-50px] mt-[60px] z-10"
        />
      </div>
    </div>
  );
};

export default GlobalPayouts;
