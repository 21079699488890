import React from "react";
import Button from "./Button";
import photo from "../assets/images/Contact-pos.png";
import photo2 from "../assets/images/closeup-contact.png";
import { Link } from "react-router-dom";

const AccessPayment = () => {
  return (
    <div className="w-full flex mb-[132px] lg:mb-[0px] lg:flex-col-reverse pl-[152px] xl:pl-[0px]:xl:px-[152px] lg:px-[70px] md:px-[30px]">
      <div className="w-1/2 lg:w-full pr-[90px] xl:pr-[0px] flex flex-col items-start ">
        <img src={photo} alt="" className="mt-[91px] lg:mt-[40px] " />
        <h1 className="font-[sarabun] mt-[16px] text-[#1E2C24] text-left font-bold text-[48px] leading-[56px]  ">
          Access payments with terminals & devices
        </h1>
        <p className="font-[sarabun] mt-[24px] text-[#3B443F] text-left text-[18px] leading-[26px] tracking-[0.002em]">
          Enhance in person payment experiences at your store locations with a
          terminal for any need. It’s easy to set -up and comes packed with
          features.
        </p>
        <div className="mt-[40px] flex justify-start lg:mb-[50px]">
        <Link to="/contactus">
          <Button className="w-[203px] h-[56px] bg-[#008037] text-[#ffffff] border-none ">
            Contact sales
          </Button>
        </Link>
        </div>
      </div>
      <div className="w-1/2 lg:w-full flex justify-end lg:justify-center mt-[26px]">
        <img src={photo2} alt="" width="746.08pxpx" height="573px" />
      </div>
    </div>
  );
};

export default AccessPayment;
