import React from "react";
import { Link } from "react-router-dom";

const OverlayTwo = () => {
  return (
    <div className="max-w-[1140px] mx-auto mt-[72px] ">
      <div className="w-full  bg-white border border-solid sticky top-[72px] left-[152px] lg:top-[64px]  opacity-[100%]">
        <div className="grid grid-cols-4 gap-x-[68px] lg:grid-flow-row md:grid-cols-2 lg:gap-y-10 px-[63px] py-[56px] ">
          <div className="mx-auto md:col-span-2 flex items-start flex-col text-center">
            <h4 className=" mb-[28px] text-[#1E2C24] font-[sarabun] font-medium text-[28px] leading-[36px] tracking-[0.01em] ">
              Products
            </h4>
            <li className="font-[sarabun] text-left font-normal text-[14px] leading-[20px] text-[#535F58] tracking-[0.01em]">
              A single platform to accept payments, protect revenue and control
              your finances
            </li>
          </div>
          <div className="md:col-span-2 ">
            <h4 className=" mb-[20px]  text-[#1E2C24] font-[sarabun] font-bold text-[18px] leading-[26px] tracking-[0.01em]">
              Online Solutions
            </h4>

            <Link to="/products">
              <li className=" text-[16px] text-[#008037] font-semibold  leading-[24px] mb-[4px] hover:text-purple-700 tracking-[0.05em] ">
                Payment gateway
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Experience the finest of Web Acquiring
              </p>
            </Link>
            <Link to="/checkout">
              <li className="mb-[4px] text-[16px] text-[#008037] font-semibold  leading-[24px] hover:text-purple-700 tracking-[0.05em] ">
                Checkout
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Payment solution for Online
                <br /> Storefront
              </p>
            </Link>
            <Link to="/mobileapp">
              <li className="text-[16px] text-[#008037] font-semibold  leading-[24px] mb-[4px] hover:text-purple-700 tracking-[0.05em] ">
                Mobile App
              </li>
            </Link>
            <p className=" text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
              Send, request, and receive money, pay merchants and bills on our
              mobile app
            </p>
          </div>
          <div className="md:col-span-2 ">
            <h4 className=" mb-[20px]  text-[#1E2C24] font-[sarabun] font-bold text-[18px] leading-[26px] tracking-[0.01em]">
              Offline Solutions
            </h4>

            <Link to="/pos">
              <li className=" text-[16px] text-[#008037] font-semibold  leading-[24px] mb-[4px] hover:text-purple-700 tracking-[0.05em] ">
                POS
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Access payment with terminals and devices
              </p>
            </Link>
            <Link to="/agentbanking">
              <li className="mb-[4px] text-[16px] text-[#008037] font-semibold  leading-[24px] hover:text-purple-700 tracking-[0.05em] ">
                Agent banking
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Making banking services more inclusive
              </p>
            </Link>
            <Link to="/sayprocessing">
              <li className=" text-[16px] text-[#008037] font-semibold  leading-[24px] mb-[4px] hover:text-purple-700 tracking-[0.05em] ">
                Switching & processing
              </li>
            </Link>
            <p className=" text-[14px] text-[#000000] font-semibold  leading-[20px] tracking-[0.01em] ">
              Supporting business growth
            </p>
          </div>
          <div className="md:col-span-2 ">
            <h4 className=" mb-[20px]  text-[#1E2C24] font-[sarabun] font-bold text-[18px] leading-[26px] tracking-[0.01em]">
              Collection & Disbursement
            </h4>

            <Link to="/collection">
              <li className=" text-[16px] text-[#008037] font-semibold  leading-[24px] mb-[4px] hover:text-purple-700 tracking-[0.05em] ">
                Collect platform
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Offers web-based solution for businesses
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayTwo;
