import React from "react";

const PandClinkingToThird = () => {
  return (
    <>
      Linking to other sites: We may provide links to third party sites, which
      may include service providers or merchants. If you follow links to sites
      not affiliated or controlled by Sayswitch, you should review their privacy
      and security policies and other terms and conditions, as they may be
      different from those of our Sites. Sayswitch does not guarantee and is not
      responsible for the privacy or security of these sites, including the
      accuracy, completeness, or reliability of their information.
      <br />
      <br />
      We cannot be responsible for the privacy policies and practices of other
      sites even if you access them using links from our Site and recommend that
      you check the policy of each site you visit and contact its owner or
      operator if you have any concerns or questions.
      <br />
      <br />
      Using Other Aggregation Websites: Other companies offer aggregation
      websites and services that allow you to consolidate your financial account
      information from different sources (such as your accounts with us or with
      other financial institutions) so that you can view all your account
      information at one online location. To do this, an aggregation provider
      may request access to personal information, such as financial information,
      usernames and passwords. You should use caution and ensure that the
      aggregator company has appropriate policies and practices to protect the
      privacy and security of any information you provide or to which they are
      gaining access. We are not responsible for the use or disclosure of any
      personal information accessed by any company or person to whom you provide
      your Site username and password. If you provide your Site username,
      password or other information about your accounts with us to an
      aggregation website, we will consider that you have authorized all
      transactions or actions initiated by an aggregation website using access
      information you provide, whether or not you were aware of a specific
      transaction or action. If you decide to revoke the authority you have
      given to an aggregation website, we strongly recommend that you change
      your password for the Site to ensure that the aggregation website cannot
      continue to access your account.
    </>
  );
};

export default PandClinkingToThird;
