import React from "react";

const PandCsecurity = () => {
  return (
    <>
      To protect personal information from unauthorized access and use, we use
      security measures that comply with applicable federal laws. These measures
      may include device safeguards and secured files and buildings as well as
      oversight of our third-party service providers to ensure information
      remains confidential and secure. We have implemented technology and
      policies to safeguard your privacy from unauthorized access and improper
      use. We use secure server software (SSL) to encrypt any financial
      information you need to input before it is sent to us.
    </>
  );
};

export default PandCsecurity;
