import React from "react";
import Photo from "../assets/images/pexels-askar-abayev-6193638 1.png";
import icon from "../assets/icons/ICONS-12.png";

const CoreValues = () => {
  return (
    <div
      className="container  space-y-8 flex 
     md:p-[30px] md:mt-[-20px] gap-12 flex-row 
     md:flex-col-reverse md:items-center "
    >
      <div className="w-1/2 md:w-full my-auto space-y-[32px]">
        <h1 className="font-[sarabun] font-bold text-[36px] leading-[44px]">
          Core Values
        </h1>
        <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          At SaySwitch, we are passionate about payment experience, and we are
          building for today and tomorrow needs.
        </p>
        <ul className="flex flex-col gap-[24px] font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            We delight our customers.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            We honor our commitments to one another.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            We communicate with Impact.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            We create our own reality.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            We build trust and maintain integrity.
          </li>
        </ul>
      </div>
      <div className="w-1/2 md:w-full block flex justify-start">
        <img
          src={Photo}
          alt="pic"
          width="554px"
          height="556px"
          className="w-full  rounded-[10px]"
        />
      </div>
    </div>
  );
};

export default CoreValues;
