import React, { useState } from "react";
import Frame from "../assets/images/Vector 1.png";
import AccessToGoodHealth from "./AccessToGoodHealth";
import FinancialWellbeing from "./FinancialWellbeing";
import FlexibilityAndTimeOff from "./FlexibilityAndTimeOff";
import SayswitchExtra from "./SayswitchExtra";
import icon from "../assets/icons/ICONSpass.png";

const WhatWeOffer = () => {
  const [value, setValue] = useState(1);

  return (
    <div className="w-full px-[152px] mt-[156px] bg-[#FFFFFF] flex flex-col gap-[60px] lg:px-[40px] md:px-[0px] pb-[156px] lg:pb-[60px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center ">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun] font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            What we offer
          </p>
        </div>
        <div className="w-full ">
          <h1 className="font-[Sarabun] px-[60px]  xl:px[0px] text-center md:text-left font-extrabold md:px-[30px] md:text-[40px] md:leading-[50px] text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            To be the best and easiest way to accept and make Payment from
            anywhere, anytime.
          </h1>
          <div className="flex flex sm:flex-col sm:gap-[20px] gap-[40px] mt-[104px] md:px-[30px] lg:mt-[24px] ">
            <li
              className={`font-[Sarabun] sm:flex sm:gap-2 sm:border-none sm:mb-[0px]  font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 1
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : " hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
              onClick={() => setValue(1)}
            >
              <span className="hidden sm:block">
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Access to good health
            </li>
            <li
              onClick={() => setValue(2)}
              className={`font-[Sarabun] sm:flex sm:gap-2 sm:border-none sm:mb-[0px]   font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 2
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : "hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
            >
              <span className="hidden sm:block">
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Financial wellbeing
            </li>
            <li
              onClick={() => setValue(3)}
              className={`font-[Sarabun] sm:flex sm:gap-2 sm:border-none  sm:mb-[0px]  font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 3
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : "hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
            >
              <span className="hidden sm:block">
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Flexibilty and time off
            </li>
            <li
              onClick={() => setValue(4)}
              className={`font-[Sarabun] sm:flex sm:gap-2 sm:border-none sm:mb-[0px] font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 4
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : "hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
            >
              <span className="hidden sm:block">
                <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
              </span>
              {""}
              Sayswitch extras
            </li>
          </div>
          <div className=" w-full border-b border-b-gray-200 mt-[20px]"></div>
        </div>
      </div>
      {value === 1 ? <AccessToGoodHealth /> : ""}
      {value === 2 ? <FinancialWellbeing /> : ""}
      {value === 3 ? <FlexibilityAndTimeOff /> : ""}
      {value === 4 ? <SayswitchExtra /> : ""}
    </div>
  );
};

export default WhatWeOffer;
