import React from "react";
import { useHistory } from "react-router-dom";
import photo from "../assets/images/African man 1.png";
import Button from "./Button";

const PartnerWithUs = () => {
  const history = useHistory();

  return (
    <div className=" w-full flex md:flex-col-reverse px-[152px] lg:px-[70px] md:px-[30px] bg-[#ffffff]">
      <div className="w-1/2  my-auto flex flex-col  md:pb-[0] md:w-full">
        <h1 className="font-[sarabun] font-bold mb-[24px] lg:mb-[12px] md:mt-[24px] text-[60px] xl:text-[45px] text-[#1E2C24] leading-[72px] xl:leading-[60px] tracking-[0.02em] lg:text-[35px] lg:leading-[50px]">
          Partner with us
        </h1>
        <p className="font-[sarabun] pr-[100px] lg:pr-[20px] md:pr-[0px] font-normal mb-[40px] lg:mb-[20px] text-[#3B443F] text-[18px] leading-[26px] tracking-[0.02em]">
          Partnering with us increases the value of your brand and expands your
          capacity altogether with our unique Switching and Transaction
          processing infrastructure. We are more than a traditional payment
          partner. We create signature experiences.
        </p>
        <div className="text-[#ffffff] md:mb-[40px] flex justify-left ">
          <div
            onClick={() => {
              history.push("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            <Button className="bg-[#008037] w-[203px] h-[56px] hover:text-white hover:bg-[#00662c] text-[#ffffff] ">
              Contact our team
            </Button>
          </div>
        </div>
      </div>
      <div className="w-1/2 my-[51px]  md:w-full flex lg:mt-[10px] justify-end lg:justify-center md:my-auto ">
        <img
          src={photo}
          alt=""
          width="533px"
          height="528px"
          className=" xl:w-[433px] xl:h-[428px] md:w-full md:h-auto rounded-[8px]"
        />
      </div>
    </div>
  );
};

export default PartnerWithUs;
