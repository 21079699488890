import React from "react";
import frame from "../assets/images/business man 1.png";
import Icon from "../assets/icons/Frame-green.png";
import man from "../assets/images/businesss set up 1 1.png";
import woman2 from "../assets/images/business to customer 1.png";
import woman from "../assets/images/positive-african-american-lady-with-smartphone-credit-card-purchasing-goods-web-sitting-desk-home_116547-32193 1.png";

const GrowYourBusiness = () => {
  return (
    <div className="bg-[#EEF2F6]  flex flex-col gap-[64px] w-full py-[156px] px-[152px] lg:px-[70px] md:px-[30px] ">
      <div className="bg-[#ffffff] w-full flex xl:flex-col rounded-[8px]">
        <div className="w-1/2 p-[49px] xl:w-full">
          <img
            src={frame}
            alt=""
            width="657.22px"
            height="440.34px"
            className="rounded-[8px] xl:w-full"
          />
        </div>
        <div className="w-1/2 lg:w-full m-auto xl:mx-[40px] xl:my-[0px] py-[49px] pr-[40px] ">
          <h1 className="mt-[4px] font-[sarabun] xl:text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Grow your business
            <br /> with Sayswitch
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            Build unique branded payment experiences
            <br /> and increase conversions
          </p>
          <ul className="mt-[32px] flex flex-col gap-[6px]">
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                Card
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                Bank Transfer
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                Bank Account
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                USSD
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                QR Payment
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                POS
              </li>
            </ul>
            <ul>
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                <img
                  src={Icon}
                  alt=""
                  width="10px"
                  height="10px"
                  className="inline-block mr-[11px]"
                />
                {""}
                Mobile Money (Coming Soon)
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <div className="flex gap-[64px] lg:flex-col ">
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full flex flex-col p-[40px] rounded-[8px]">
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Take payments online for any business setup
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            We offer a full range of payment options, for any business and
            industry, so you can give your customers the experiences they
            expect.
          </p>
          <img
            src={man}
            alt=""
            width="626.05px"
            height="354.34px"
            className="mt-[88px] lg:mt-[40px] rounded-[8px] lg:w-full"
          />
        </div>
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full  flex flex-col p-[40px] rounded-[8px]">
          <img
            src={woman}
            alt=""
            width="498px"
            height="332px"
            className="mb-[68px] rounded-[8px] lg:w-full"
          />
          <h1 className="font-[sarabun] xl:text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Business to customer
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            Build unique branded payment experiences and increase conversions
          </p>
          <ul>
            <li className="mt-[32px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
              <img
                src={Icon}
                alt=""
                width="10px"
                height="10px"
                className="inline-block mr-[11px] rounded-[2px]"
              />
              {""}
              Customize payment flows.
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-[#ffffff] w-full flex xl:flex-col rounded-[8px]">
        <div className="w-1/2 p-[49px] xl:w-full">
          <img
            src={woman2}
            alt=""
            width="648.2px"
            height="433git px"
            className="rounded-[8px] xl:w-full"
          />
        </div>
        <div className="w-1/2 lg:w-full lg:pr-[70px]  m-auto xl:mx-[40px] xl:my-[0px] py-[49px] pr-[40px] ">
          <h1 className="mt-[4px] font-[sarabun] xl:text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Business to business
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            Win more merchants with flexible payment options and reduce
            reconciliation issues.
          </p>
          <ul className="mt-[32px] flex flex-col gap-[6px]">
            <ul className="flex gap-[11px] items-baseline ">
              <img
                src={Icon}
                alt=""
                width="10px"
                height="10px"
                className="inline-block rounded-[2px]"
              />
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                Accept payment links, bank transfer, bank account, Sayswitch
                account and more
              </li>
            </ul>
            <ul className="flex gap-[11px] items-baseline ">
              <img
                src={Icon}
                alt=""
                width="10px"
                height="10px"
                className=" inline-block rounded-[2px]"
              />
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                Reconcile and match transactions dispute and refunds
                effortlessly.
              </li>
            </ul>
            <ul className="flex gap-[11px] items-baseline ">
              <img
                src={Icon}
                alt=""
                width="10px"
                height="10px"
                className=" inline-block rounded-[2px]"
              />
              <li className=" font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
                Quicker settlements and payouts.
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GrowYourBusiness;
