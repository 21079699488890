import React from "react";
import photo from "../assets/images/young-woman-doing-her-shopping-online 1.png";
import Icon from "../assets/images/Featured icon1.png";
import Icon2 from "../assets/icons/green-lock.png";
import Icon3 from "../assets/icons/bin.png";

const SeamlessPayment = () => {
  return (
    <div className="w-full min-h-[1010px] mb-[120px] py-[156px] flex flex-col px-[152px] lg:px-[70px] md:px-[30px] bg-[#ffffff]">
      <div className="w-full mb-[96px]">
        <h1 className="font-[Sarabun] font-bold text-[36px] leading-[44px] tracking-[0.04em] ">
          Seamless payment experience for
          <br /> your customers
        </h1>
      </div>
      <div className="w-full grid grid-cols-3 grid-flow-row justify-items-center lg:grid-cols-2  grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center ">
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px]">
            <img src={Icon} alt="" className="mb-[72px] " />
            <h5 className="mb-[12px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Multiple Payment Methods
            </h5>
            <p className="font-normal text-[16px] lg:gap-r-0 leading-[24px] tracking-[0.02em] text-[#3B443F]">
              SaySwitch checkout offers the payment methods your customers know
              and trust which means they can pay you how they love to pay
              through Card payments, Accounts, Bank Transfer etc.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img src={Icon2} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Secure and Scalable
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Security standards using encryption to offer a safe and a
              friction-free experience for your customers. We are PCIDSS
              certified. Our payment platform allows your customers to pay you
              within a secure environment.
            </p>
          </div>
        </div>

        <div className="bg-[#ffffff] mb-[120px] xl:mb-[24px] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img src={Icon3} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Make payments anywhere you go
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Customers can make payments on mobile, desktop through various
              payment methods such as Card, Bank Account, NQR, Bank Transfers
              etc. and you can receive payments from customers from anywhere
              they are in the world.
            </p>
          </div>
        </div>
      </div>
      <img src={photo} width="1136px" height="596px" alt="" className=" " />
    </div>
  );
};

export default SeamlessPayment;
