import React from "react";

const PandCtermsOfUse = () => {
  return (
    <>
      By using this website (www.sayswitchgroup.com), you agree to these Terms
      of Use. The website Privacy Policy, Cookies Policy and Terms of Service
      (where applicable) are incorporated by reference into these Terms of Use.
    </>
  );
};

export default PandCtermsOfUse;
