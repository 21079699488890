import React from "react";

function PandCsecurityOfData() {
  return (
    <>
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch protects your information using physical, technical, and
          administrative security measures to reduce the risks of loss, misuse,
          unauthorized access, disclosure, and alteration.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch runs a secure security architecture from applications,
          software, and processes to secure data from outsiders and abuse from
          insiders.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch uses advanced tools to secure data at rest and data in
          transit.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch complies with a management system for business continuity,
          quality management, information security and payment cards.
        </li>
      </ol>
      Only a few employees based on role-based access are permitted by Sayswitch
      to have access to personal information. Some of the safeguards we use are
      firewalls and data encryption, physical access controls to our data
      centers, and information access authorization controls. We have also taken
      additional measures by ensuring our system complies with industry
      information security standards.
    </>
  );
}

export default PandCsecurityOfData;
