import React from "react";
import DownloadSayswitch from "./DownloadSayswitch";
import GettingStarted from "./GettingStarted";
import MakingBanking from "./MakingBanking";
import OurAgents from "./OurAgents";

const AgentBanking = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <MakingBanking />
      <OurAgents />
      <GettingStarted />
      <DownloadSayswitch />
    </div>
  );
};

export default AgentBanking;
