import React from "react";

const PandCapplicableLaw = () => {
  return (
    <>
      These Terms of Use shall be interpreted and governed by the laws currently
      in force in the Federal Republic of Nigeria.
    </>
  );
};

export default PandCapplicableLaw;
