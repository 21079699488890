import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import heroman from "../assets/images/top-payment-apps 1.png";

const Homepage1 = () => {
  return (
    <div
      className="w-full flex flex-row bg-[#FFFFFF] lg:flex-col-reverse space-y-12 gap-12 
    md:gap-[0] pb-[70px] pl-[152px] pr-[0]
     lg:pl-[0px] lg:pr[70px] md:pl-[30px] md:mt-[40px]  md:px-[30px] md:flex-col 
     md:space-x-0 md:container md:items-center "
    >
      <div className=" flex flex-col lg:px-[70px] md:px-[0px] w-1/2 h-full lg:w-full my-auto md:pb-[0] md:w-full">
        <h1 className="font-sarabun font-[800] mb-[24px] xl:mb-[20px] pr-[140px] xl:pr-[0px] text-[48px] text-[#1E2C24] leading-[56px] -tracking-[0.02em] md:text-4xl">
          A complete feature - rich payment solution for both offline and online
          acquiring.
        </h1>
        <p className="font-sarabun font-normal mb-[40px] pr-[120px] xl:pr-[0px] xl:mb-[24px] text-[#3B443F] text-[18px] leading-[26px] tracking-[0.01em] sm:max-w-sm md:text-sm">
          The financial technology platform that helps you to accept, process
          and settle payment online or offline anywhere.
        </p>
        <div className="text-[#ffffff] flex justify-start md:flex-col md:items-start gap-6 md:gap-2">
          <a href="https://merchant.sayswitchgroup.com/create-account" target="" rel="noopener noreferrer">
            <Button className="bg-[#008037] w-[203px] h-[56px] hover:text-white hover:bg-[#00662c] text-[#ffffff] ">
              Create An Account
            </Button>
          </a>

          {/* <Button className=" bg-[#ffffff] w-[203px] h-[56px] border-[#008037] text-[#008037]">
            Log in
          </Button> */}
        </div>
      </div>
      <div className=" md:w-full lg:w-full  md: my-auto w-1/2">
        <img
          src={heroman}
          width="774"
          height="544px"
          alt="pic"
          className=" w-full "
        />
      </div>
    </div>
  );
};

export default Homepage1;
