import React from "react";

const WithPartnership = () => {
  return (
    <div className="w-full px-[152px] lg:px-[70px] md:px-[30px] py-[156px] lg:py-[96px] bg-[#ffffff]">
      <h1 className="font-[Sarabun] text-[#1E2C24] font-normal text-[60px] xl:text-[50px] lg:text-[40px] md:text-[30px] sm:text-[20px] leading-[88px] lg:leading-[66px] xl:leading[77px] md:leading-[55px] sm:leading-[44px] tracking-[0.04em] ">
        With a partnership with SaySwitch, anything is possible. Our work ethics
        and business models are based on your growth plan because we believe
        that together we can make payment seamless.
      </h1>
    </div>
  );
};

export default WithPartnership;
