import React from "react";

const PandCUpdatesModificationAndAmends = () => {
  return (
    <>
      We may need to update, modify or amend our Acceptable Use Policy at any
      time. We reserve the right to make changes to this Acceptable Use Policy.
      We advise that you check this page often, referring to the date of the
      last modification on the page.
    </>
  );
};

export default PandCUpdatesModificationAndAmends;
