import React from "react";
import Icon from "../assets/images/Frame 115449.png";
import Icon2 from "../assets/images/Frame 115449-2.png";
import Icon3 from "../assets/images/Frame 115448.png";

const WhyChooseUs = () => {
  return (
    <div className="w-full  py-[156px] flex flex-col px-[152px] lg:px-[70px] md:px-[30px] bg-[#F3F6F9]">
      <div className="w-full mb-[96px] ">
        <h1 className="font-[Sarabun] mb-[24px] text-[#1E2C24] font-bold text-[36px] leading-[44px] tracking-[0.04em] ">
          Why choose us as a payment partner?
        </h1>
        <p className="font-[Sarabun] text-[#3B443F] pr-[300px] lg:pr-[0] font-normal text-[18px] leading-[26px] tracking-[0.04em] ">
          We will support you and your team in achieving your desired goal(s) on
          payment and transaction processing services. With our wide range of
          services tailored just for you, we would ensure a win-win partnership.
        </p>
      </div>
      <div className="w-full grid grid-cols-3 grid-flow-row justify-items-center md:justify-items-start lg:grid-cols-2  grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center ">
        <div className=" max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="pr-[24px] py-[40px]">
            <img src={Icon} alt="" className="mb-[72px] " />
            <h5 className="mb-[12px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Marketing
            </h5>
            <p className="font-normal text-[16px] lg:gap-r-0 leading-[24px] tracking-[0.02em] text-[#3B443F]">
              We take time to build relationships with our partners and love to
              collaborate with them to create marketing campaigns.
            </p>
          </div>
        </div>
        <div className=" max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="pr-[24px] py-[40px] lg:w-full ">
            <img src={Icon2} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Partner Network
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              With our partners we are able to focus on your needs where each
              customer has a unique custom-made payment solution.
            </p>
          </div>
        </div>
        <div className="max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="pr-[24px] py-[40px] lg:w-full ">
            <img src={Icon3} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Dedicated Technical Account Manager
            </h5>
            <p className="font-normal text-[16px]  leading-[24px] tracking-[0.02em] text-[#3B443F]">
              With partners we grow together and by supporting you with our
              dedicated technical account manager.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
