import React from "react";
import Image1 from "../assets/images/Shape.png";
import Image2 from "../assets/images/Vector-3.png";
import Image3 from "../assets/images/Zenith-Bank-900x0 1.png";
import Image4 from "../assets/images/1110px-Verifone_Logo 1.png";
import Image5 from "../assets/images/Wema bank.png";
import Image6 from "../assets/images/black-logo 1.png";

const AboutPartners = () => {
  return (
    <div className=" w-full md:hidden mb-[156px]">
      <div className="w-[1136px] xl:w-[900px] lg:[700px] mx-auto lg:mx-[30px] h-[373px] lg:w-auto flex flex-col items-center gap-[57px] lg:gap-[24px]  px-[158px] py-[56px] rounded-[20px] mt-[-156px] bg-[#ffffff]">
        <h3 className="font-[sarabun] font-bold text-[36px] text-[#008037] leading-[44px] tracking-[-0.02em]">
          Partners
        </h3>
        <div className=" grid grid-cols-3 xl:grid-cols-2 grid-rows-2 md:grid-cols-1 md:grid-rows-6 gap-[74px] justify-content-center items-center">
          <img src={Image1} alt="" width="87.58px" height="41.13px" />
          <img src={Image6} alt="" width="230px" height="38.33px" />
          <img src={Image3} alt="" width="52.39px" height="58.61px" />
          <img src={Image4} alt="" width="139.42px" height="25.5px" />
          <img src={Image5} alt="" width="90px" height="50.43px" />
          <img src={Image2} alt="" width="53.28px" height="55.31px" />
        </div>
      </div>
    </div>
  );
};

export default AboutPartners;
