import React from "react";

function PandCinformationAuto() {
  return (
    <>
      We and our third-party service providers may collect and use other
      information in a variety of ways, including:
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Log files/IP addresses: When you visit the Site our web server
          automatically records your IP address. This IP address is not linked
          to any of your personal information. We use IP addresses to help us
          administer the Site and to collect demographic information for
          aggregation purposes.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Other technologies including pixel tags, web beacons, and clear GIFs:
          These may be used in connection with some Site pages, downloadable
          mobile applications and HTML-formatted email messages to measure the
          effectiveness of our communications, the success of our marketing
          campaigns, to compile statistics about usage and response rates, to
          personalize/tailor your experience while engaging with us online and
          offline, for fraud detection and prevention, for security purposes,
          for advertising, and to assist us in resolving account holders'
          questions regarding use of our Site.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Aggregated and De-identified Data: Aggregated and De-identified Data
          is data that we may create or compile from various sources, including
          but not limited to accounts and transactions. This information, which
          does not identify individual account holders, may be used for our
          business purposes, which may include offering products or services,
          research, marketing or analyzing market trends, and other purposes
          consistent with applicable laws.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Through your browser or device: Certain information is collected by
          most browsers and/or through your device, such as your Media Access
          Control (MAC) address, device type, screen resolution, operating
          system version and internet browser type and version. We use this
          information to ensure Sites function properly, for fraud detection and
          prevention, and security purposes.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          We may also gather other non-personal information (from which we
          cannot identify you) such as the type of your internet browser which
          we use to provide you with a more effective service.
        </li>
      </ol>
      <span id="cookies"></span>
    </>
  );
}

export default PandCinformationAuto;
