import React from "react";
import Button from "./Button";

function JoinOurTeam() {
  return (
    <div className="w-full  bg-[#ffffff] px-[152px] py-[120px] lg:py-[60px] lg:px-[40px] md:px-[0px]">
      <div className="w-full  flex  lg:flex:col px-[132px] xl:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
        <div className="w-full py-[72px] mx-auto  lg:mx-auto flex flex-col justify-center align-center items-center ">
          <h1 className="mb-[24px] text-[#ffffff] text-center sm:text-left font-bold text-[18px] leading-[26px] tracking-[0.01em]">
            Join our team
          </h1>
          <p className="font-bold text-[36px] sm:text-[30px] text-center leading-[44px] sm:leading-[38px] tracking-[-0.001em] mb-[40px] text-[#ffffff]  ">
            Our team is stacked with dynamic individuals who help us build that
            experience everyday
          </p>
          <div className="flex w-full justify-center ">
            <Button className="bg-[#018038] h-[56px] min-w-[239px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
              View open roles
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinOurTeam;
