import React from "react";

const PandClegalDisputes = () => {
  return (
    <>
      We shall make an effort to settle all disputes amicably. Any dispute
      arising out of this Terms of Use, which cannot be settled, by mutual
      agreement/negotiation within 1 (one) month shall be referred to
      arbitration by a single arbitrator at the Lagos Multi-Door Courthouse
      (“LMDC”) and governed by the Arbitration and Conciliation Act, Cap A10,
      Laws of the Federal Republic of Nigeria. The arbitrator shall be appointed
      by both of us (we and you), where both of us are unable to agree on the
      choice of an arbitrator, the choice of arbitration shall be referred to
      the LMDC. The findings of the arbitrator and subsequent award shall be
      binding on both of us. Each of us shall bear our respective costs in
      connection with the Arbitration. Venue for the arbitration shall be Lagos,
      Nigeria.
    </>
  );
};

export default PandClegalDisputes;
