import Frame from "../assets/images/Vector 1.png";
import CoreValues from "./CoreValues";
import SocialResponsibility from "./SocialResponsibility";

const OurVision = ({value, setValue}) => {
  

  return (
    <div className="w-full px-[152px] mt-[-120px] pt-[152px] bg-[#FFFFFF] flex flex-col gap-[60px] lg:px-[40px] md:px-[0px] pb-[156px] lg:pb-[60px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center ">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun] font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Our vision
          </p>
        </div>
        <div className="w-full ">
          <h1 className="font-[Sarabun] px-[60px]  xl:px[0px] text-center md:text-left font-extrabold md:px-[30px] md:text-[40px] md:leading-[50px] text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            To be the best and easiest way to accept and make Payment from
            anywhere, anytime.
          </h1>
          <div className="flex gap-[40px] mt-[104px] md:px-[30px] lg:mt-[24px] ">
            <li
              className={`font-[Sarabun] font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 1
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : " hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
              onClick={() => setValue(1)}
            >
              Core values
            </li>
            <li
              onClick={() => setValue(2)}
              className={`font-[Sarabun] font-normal text-[#3B443F] hover:text-[gray] hover:scale-x-[101%] text-[18px] leading-[26px] tracking-[0.02em] cursor-pointer relative ${
                value === 2
                  ? " border-b-2 border-b-[#008037] mb-[-22px] "
                  : "hover:border-b-gray-400 hover:border-b-2 hover:mb-[-21px] hover:pb-[19px] "
              }`}
            >
              Social responsibility
            </li>
          </div>
          <div className=" w-full border-b border-b-gray-200 mt-[20px]"></div>
        </div>
      </div>
      {value === 1 ? <CoreValues /> : <SocialResponsibility />}
    </div>
  );
};

export default OurVision;
