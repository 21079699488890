import React from "react";

const PandCRestrictedActivities = () => {
  return (
    <>
      You may not use Sayswitch in connection with any product, service,
      transaction or activity that:
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          violates any law or government regulation, or promotes or facilitates
          such by third parties;
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Violates any rule or regulation of Visa, MasterCard, Verve or any
          other electronic funds transfer network (each, a “Card Network”);
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Is fraudulent, deceptive, unfair or predatory;
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Causes or threatens reputational damage to us or any Card Network;
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Involves any of the business categories listed in clause 2 below; or
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Results in or creates a significant risk of chargebacks, penalties,
          damages or other harm or liability.
        </li>
      </ol>
    </>
  );
};

export default PandCRestrictedActivities;
