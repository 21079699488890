import React from "react";
import Fram2 from "../assets/images/Vector 1.png";
import frame from "../assets/images/gettingstarted.png";
import circle from "../assets/images/Group 9866.png";
import circle2 from "../assets/images/circle-2.png";
import circle3 from "../assets/images/circle-3.png";
import rectangle from "../assets/images/Rectangle 2857.png";

const GettingStarted = () => {
  return (
    <div className="w-full pt-[120px] pb-[156px] flex flex-col px-[152px]  lg:px-[70px] md:px-[30px] bg-[#ffffff]">
      <div className="w-full mb-[120px] lg:mb-[60px]">
        <div className="w-full mb-[24px] flex flex-col justify-center gap-[12px] items-center">
          <img src={Fram2} alt="" className="w-[41px]" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Getting started
          </p>
        </div>
        <h1 className="font-[Sarabun] text-[#1E2C24] text-center font-bold text-[48px] leading-[56px] ">
          Get started on Sayswitch today!
        </h1>
      </div>
      <div className="flex lg:flex-col-reverse">
        <div className="w-1/2 lg:w-full lg:pr-[43px] flex-col items-start">
          <div className="flex w-full justify-start mb-[12px] lg:w-full">
            <div className="flex flex-col items-center mr-[16px]">
              <img
                src={circle}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
              <img
                src={rectangle}
                alt=""
                className="min-h-[224px] min-w-[3px] inline"
              />
            </div>
            <div className=" w-full flex flex-col items-start gap-[12px]">
              <h1 className="text-[#1E2C24]">Download app</h1>
              <p className="text-[#3B443F]">
                Get the SaySwitch App on Google Play Store for Android or App
                Store for iOS.
              </p>
            </div>
          </div>
          <div className="flex w-full justify-start mb-[12px] gap-[16px]  lg:w-full">
            <div className="flex flex-col items-center">
              <img
                src={circle2}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
              <img
                src={rectangle}
                alt=""
                className="min-h-[224px] min-w-[3px] inline"
              />
            </div>
            <div className="flex flex-col items-start gap-[12px]">
              <h1 className="text-[#1E2C24]">Register within minutes</h1>
              <p className="text-[#3B443F]">
                Tell us a few details about you and create a SaySwitch account
              </p>
            </div>
          </div>
          <div className="flex w-full justify-start mb-[12px] gap-[16px]  lg:w-full">
            <div className="flex flex-col items-center">
              <img
                src={circle3}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
            </div>
            <div className=" flex flex-col items-start gap-[12px]">
              <h1 className="text-[#1E2C24]">Start banking</h1>
              <p className="text-[#3B443F]">
                You’re ready to go. Carry out all your banking transactions
                within the app, from receiveing to sending money and bill
                payments.
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2  lg:w-full flex justify-end lg:justify-center lg:mb-[24px] items-center">
          <img src={frame} alt="" width="309.3px" height="617px" />
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
