import React from "react";

const PandCRefunds = () => {
  return (
    <>
      You agree that you are solely responsible for accepting and processing
      returns of your products and services. We are under no obligation to
      process returns of your products and services, or to respond to your
      customers’ inquiries about returns of your products and services. You
      agree to submit all Refunds for returns of your products and services that
      were paid for through Sayswitch to your customers in accordance with this
      Agreement and relevant Card Network Rules.
    </>
  );
};

export default PandCRefunds;
