import React from "react";
import GlobalAcceptance from "./GlobalAcceptance";
import GrowYourBusiness from "./GrowYourBusiness";
import OurGoal from "./OurGoal";

import ReadyToStart from "./ReadyToStart";
import TheBestWay from "./TheBestWay";

const PaymentGateway = () => {
  return (
    <div>
      <TheBestWay />
      <OurGoal />
      <GlobalAcceptance />
      <GrowYourBusiness />
      <ReadyToStart />
    </div>
  );
};

export default PaymentGateway;
