import React from "react";
import { Link } from "react-router-dom";
import photo from "../assets/images/Charming lady 1.png";
import Button from "./Button";

const AboutCareers = () => {
  return (
    <div className=" w-full flex flex-col px-[152px] lg:pb-[70px] lg:px-[70px] md:px-[30px] bg-[#F3F6F9]">
      <div className="flex  lg:mb-[50px] justify-start mt-[21px] xs:mt-[10px] gap-[28px]">
        <Link to="/about">
          <li className="text-[#1E2C24] font-[sarabun] font-medium text-[16px] leading-[24px] tracking-[0.01em]">
            Overview
          </li>
        </Link>
        <Link>
          <li className="text-[#008037] font-[sarabun] font-medium text-[16px] leading-[24px] tracking-[0.01em]">
            Careers
          </li>
        </Link>
      </div>
      <div className="w-full flex lg:flex-col-reverse ">
        <div className="w-[50%]  mt-[107px] lg:mt-[32px] xl:w-[60%] flex flex-col  h-full lg:w-full ">
          <h1 className="[font-sarabun] font-bold mb-[24px] text-[60px] lg:text-center md:text-left text-[#1E2C24] leading-[72px] -tracking-[0.02em] md:text-4xl">
            Join us at <span className="text-[#008037]">Sayswitch</span>
          </h1>
          <p className="font-[sarabun] lg:text-center md:text-left  font-normal mb-[40px] text-[#3B443F] text-[18px] leading-[26px] tracking-[0.02em]">
            We strive to provide our team and their loved ones with a
            world-class benefits experience, focused on supporting their
            physical, financial, and emotional wellbeing.
          </p>
          <div className="text-[#ffffff] flex lg:justify-center md:justify-left flex-row md:flex-col gap-6 xl:gap-2 ">
            <Link to="/">
              <Button className="bg-[#008037] w-[203px] h-[56px] hover:text-white hover:bg-[#00662c] text-[#ffffff] ">
                What we offer
              </Button>
            </Link>
            <Button className=" bg-[#ffffff] border-[#008037] w-[203px] h-[56px] text-[#008037] hover:text-[#ffffff] hover:bg-[#008037] ">
              View open roles
            </Button>
          </div>
        </div>
        <div className="w-[50%]  md:w-full lg:w-full flex lg:mt-[10px] items-start justify-end lg:justify-center md: my-auto ">
          <img
            src={photo}
            alt=""
            className=" w-[482.11px] xl:w-[382.11px] sm:w-[282.11px] h-[670px] xl:h-[570px] sm:h-[470px] xs:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutCareers;
