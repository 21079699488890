import React from "react";
import Icon from "../assets/icons/Iconenvelop.png";
import Icon2 from "../assets/icons/iconspark.png";
import Icon3 from "../assets/icons/iconbars.png";
import Icon4 from "../assets/icons/iconsmile.png";
import logo from "../assets/images/ourgoal.png";

const OurGoal = () => {
  return (
    <div className="w-full pt-[120px] pb-[156px] flex flex-col px-[152px]  lg:px-[70px] md:px-[30px] bg-[#ECF3F9]">
      <div className="w-full mb-[120px] lg:mb-[60px]">
        <div className="flex justify-center md:justify-start mb-[20px]">
          <img src={logo} alt="" className="h-[60px] w-[230.05px]" />
        </div>
        <h1 className="font-[Sarabun] text-center md:text-left font-bold text-[36px] leading-[44px] tracking-[0.04em] ">
          Everything you need to accept
          <br /> payment and control your finances
        </h1>
      </div>
      <div className="w-full grid grid-cols-4 grid-flow-row justify-items-center lg:grid-cols-2  grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center ">
        <div className="bg-[#ffffff] max-w-[266px] min-h-[382px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px]">
            <img
              src={Icon2}
              alt=""
              width="48px"
              height="48px"
              className="mb-[72px] "
            />
            <h5 className="mb-[12px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Track cashflow
            </h5>
            <p className="font-normal text-[16px] lg:gap-r-0 leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Stay on top of your working capital, taxes and cashflow to ensure
              your business is performing at it’s best.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[266px] min-h-[382px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img
              src={Icon3}
              alt=""
              width="48px"
              height="48px"
              className="mb-[72px]"
            />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Full support
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Expect the best from us. Get a dedicated support team that will
              handle all your support needs, any time or day.
            </p>
          </div>
        </div>

        <div className="bg-[#ffffff] max-w-[266px] min-h-[382px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img
              src={Icon4}
              alt=""
              width="48px"
              height="48px"
              className="mb-[72px]"
            />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Always improving
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              We’re proud to ship improvements to our platform quickly and
              regularly. Check out our changelog.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[266px] min-h-[382px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img
              src={Icon}
              alt=""
              width="48px"
              height="48px"
              className="mb-[72px]"
            />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Powerful software
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Included in our services, each customer gets in-house software to
              access live financial dashboards.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGoal;
