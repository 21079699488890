import React from "react";
import Frame from "../assets/images/Vector 1.png";
import PaymentInt from "./PaymentInt";
import GetPaidWithQR from "./GetPaidWithQR";
import RequestPayment from "./RequestPayment";

const AcceptPayment = () => {
  return (
    <>
      <div className="w-full px-[152px] mb-[120px] flex flex-col gap-[120px] lg:px-[40px] md:px-[0px] pb-[156px]">
        <div className="w-full mb-[120px] flex-wrap flex flex-col justify-center gap-[24px] items-center">
          <div className="w-full flex flex-col justify-center gap-[12px] items-center">
            <img src={Frame} alt="" className="w-[41px]" />
            <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
              Payment
            </p>
          </div>
          <div>
            <h1 className="font-[Sarabun] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
              Accept payments from
              <br /> anyone anywhere
            </h1>
          </div>
        </div>
        <>
          <RequestPayment />
        </>
        <>
          <GetPaidWithQR />
        </>
        <>
          <PaymentInt />
        </>
      </div>
    </>
  );
};

export default AcceptPayment;
