import React from "react";

const PandCsocialMedia = () => {
  return (
    <>
      Sayswitch provides experiences on social media platforms including, but
      not limited to Facebook, Twitter, and LinkedIn that enable online sharing
      and collaboration among users who have registered to use them. Any content
      you post on official Sayswitch managed social media pages, such as
      pictures, information, opinions, or any personal information that you make
      available to other participants on these social platforms, is subject to
      the Terms of Use and Privacy Policies of those respective platforms.
      Please refer to them to better understand your rights and obligations
      concerning such content. Additionally, please note that when visiting any
      official Sayswitch social media pages, you are also subject to Sayswitch
      Privacy Policy and Social Media User Terms.
      <span id="isopolicy"></span>
    </>
  );
};

export default PandCsocialMedia;
