import React from "react";

const PandCagreement = () => {
  return (
    <>
      These Merchant Terms of Service is an agreement between you and Sayswitch.
      It details Sayswitch’s obligations to you. It also highlights certain
      risks on using the services and you must consider such risks carefully as
      you will be bound by the provision of this Agreement through your use of
      this Portal or any of our services.
    </>
  );
};

export default PandCagreement;
