import React from "react";

const PandCtermination = () => {
  return (
    <>
      You may terminate this Agreement by closing your Sayswitch Account. We may
      suspend your Sayswitch Account and your access to Sayswitch services and
      any funds, or terminate this Agreement, if;
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          You do not comply with any of the provisions of this Agreement;
        </li>
        <li className="list-decimal list-outside  mb-[16px]  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          We are required to do so by a Law;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          We are directed by a Card Network or issuing financial institution; or
        </li>
        <li className="list-decimal list-outside  mb-[16px]  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Where a suspicious or fraudulent transaction occurs.
        </li>
      </ol>
    </>
  );
};

export default PandCtermination;
