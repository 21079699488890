import React from "react";
import logo from "../assets/images/getstarted.png";
import frame from "../assets/images/Frame 115475.png";
import circle from "../assets/images/Group 9866.png";
import circle2 from "../assets/images/circle-2.png";
import circle3 from "../assets/images/circle-3.png";
import rectangle from "../assets/images/Rectangle 2857.png";

function HowToGetStarted() {
  return (
    <div className="w-full pt-[120px] pb-[156px] flex flex-col px-[152px]  lg:px-[70px] md:px-[30px] bg-[#121317]">
      <div className="w-full mb-[120px] lg:mb-[60px]">
        <div className="flex justify-center md:justify-start mb-[20px]">
          <img src={logo} alt="" className="h-[60px] w-[230.05px]" />
        </div>
        <h1 className="font-[Sarabun] text-[#ffffff] text-center md:text-left font-bold text-[48px] leading-[56px] ">
          Get started on Sayswitch today!
        </h1>
      </div>
      <div className="flex lg:flex-col-reverse">
        <div className="w-1/2 lg:w-full lg:pr-[43px] flex-col items-start">
          <div className="flex w-full justify-start mb-[12px] lg:w-full">
            <div className="flex flex-col items-center mr-[16px]">
              <img
                src={circle}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
              <img
                src={rectangle}
                alt=""
                className="min-h-[224px] min-w-[3px] inline"
              />
            </div>
            <div className="text-[#ffffff] w-full flex flex-col items-start gap-[12px]">
              <h1 className="">Download app</h1>
              <p>
                Get the SaySwitch App on Google Play Store for Android or App
                Store for iOS.
              </p>
            </div>
          </div>
          <div className="flex w-full justify-start mb-[12px] gap-[16px]  lg:w-full">
            <div className="flex flex-col items-center">
              <img
                src={circle2}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
              <img
                src={rectangle}
                alt=""
                className="min-h-[224px] min-w-[3px] inline"
              />
            </div>
            <div className="text-[#ffffff] flex flex-col items-start gap-[12px]">
              <h1 className="">Register within minutes</h1>
              <p>
                Tell us a few details about you and create a SaySwitch account
              </p>
            </div>
          </div>
          <div className="flex w-full justify-start mb-[12px] gap-[16px]  lg:w-full">
            <div className="flex flex-col items-center">
              <img
                src={circle3}
                alt=""
                className="mb-[12px] min-w-[38px] min-h-[38px] inline"
              />
            </div>
            <div className="text-[#ffffff] flex flex-col items-start gap-[12px]">
              <h1 className="">Start banking</h1>
              <p>
                You’re ready to go. Carry out all your banking transactions
                within the app, from receiveing to sending money and bill
                payments.
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/2  lg:w-full flex justify-end lg:justify-center lg:mb-[24px] items-center">
          <img src={frame} alt="" width="506px" height="840px" />
        </div>
      </div>
    </div>
  );
}

export default HowToGetStarted;
