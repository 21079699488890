import React from "react";
import { useHistory } from "react-router-dom";
import Button from "./Button";

const TalkToUsAboutPOS = () => {
  const history = useHistory();

  return (
    <div className="w-full  bg-[#ffffff] px-[152px] py-[120px] lg:py-[60px] lg:px-[40px] md:px-[0px]">
      <div className="w-full  flex  lg:flex:col px-[300px] xl:px-[100px] lg:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
        <div className="w-full py-[72px] mx-auto  lg:mx-auto flex flex-col justify-center align-center md:items-start items-center ">
          <p className="font-bold text-[36px] text-center md:text-left  leading-[44px] tracking-[-0.001em] mb-[40px] text-[#ffffff]  ">
            Talk to us about point of sale
          </p>
          <h1 className="mb-[24px] text-[#D6DBD8] text-center md:text-left font-[sarabun] font-bold text-[16px] leading-[24px] tracking-[0.02em]">
            Our terminals are flexible, portable, and powerful solutions capable
            of doing business anywhere.
          </h1>

          <div className="mt-[40px] flex md:justify-start  ">
            <div
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              <Button className="w-[239px] h-[50px] bg-[#008037] text-[#ffffff] border-none ">
                Contact sales
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalkToUsAboutPOS;
