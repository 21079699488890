import React from "react";

const PandCMiscellaneous = () => {
  return (
    <>
      You agree that all agreements, notices, disclosures and other
      communications that we provide to you electronically satisfy any legal
      requirement that such communications be in writing. Assigning or
      sub-contracting any of your rights or obligations under these Terms of Use
      to any third party is prohibited. We reserve the right to transfer, assign
      or sub-contract the benefit of the whole or part of any rights or
      obligations under these Terms of Use to any third party.
    </>
  );
};

export default PandCMiscellaneous;
