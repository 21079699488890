import React from "react";
import { motion } from "framer-motion";

export default function Button({ type = "submit", className = "", children }) {
  return (
    <motion.div
      transition={{ type: "spring" }}
      whileTap={{ scale: 0.9, rotate: 2, opacity: 0.8 }}
    >
      <button
        type={type}
        className={
          `  outline-none rounded-[8px] font-Sarabun font-medium text-[16px] leading-[16px] select-none border py-[12px]  
          } ` + className
        }
      >
        {children}
      </button>
    </motion.div>
  );
}
