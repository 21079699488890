import React from "react";

const PandCoUrFeesAndPrices = () => {
  return (
    <>
      You agree to pay us for the services we render as a gateway for merchant
      registration and preorder of POS machines and similar services. Our Fees
      will be calculated as demonstrated on the Pricing page on the Portal and
      can be calculated on the same page. The Fees on our Pricing page is
      integral to and forms part of this Agreement. We reserve the right to
      revise our Fees.
      <br />
      <br />
      In the event that we revise our fees we will notify you within 5 days of
      such change.
    </>
  );
};

export default PandCoUrFeesAndPrices;
