import React from "react";

const PandCReserves = () => {
  return (
    <>
      In our sole discretion, we may place a Reserve on a portion of your
      Payouts by holding for a certain period such portion where we believe
      there is a high level of risk associated with your business. If we take
      such steps, we will provide you with the terms of the Reserve which may
      include the percentage of your Payouts to be held back, period of time and
      any other such restrictions that Sayswitch may deem necessary. Where such
      terms are changed, we will notify you. You agree that you will remain
      liable for all obligations related to your transactions even after the
      release of any Reserve. In addition, we may require you to keep your Bank
      Account available for any open settlements, Chargebacks and other
      adjustments. To secure your performance of this Agreement, you grant
      Sayswitch a legal claim to the funds held in the Reserve as a lien or
      security interest for amounts payable by you.
    </>
  );
};

export default PandCReserves;
