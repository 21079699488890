import React from "react";
import PaymentGateway from "../components/PaymentGateway";

const ProductsView = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <PaymentGateway />
    </div>
  );
};

export default ProductsView;
