import React from "react";
import { useHistory } from "react-router-dom";
import Frame from "../assets/images/Vector 1.png";
import Photo from "../assets/images/Payments 1.png";

const MobileApp = () => {
  const history = useHistory();

  return (
    <div className="w-full px-[152px] py-[152px] lg:py-[60px] bg-[#ffffff] flex flex-col gap-[120px] lg:px-[40px] md:px-[0px] ">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Mobile App
          </p>
        </div>
        <div>
          <h1 className="font-[Sarabun] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            Secured payment app
            <br /> for everyone
          </h1>
        </div>
      </div>
      <div
        className="container  space-y-8 flex 
     md:p-[30px] md:mt-[-20px] gap-12 flex-row 
     md:flex-col-reverse md:items-center "
      >
        <div className="w-1/3 md:w-full my-auto space-y-[12px]">
          <h1 className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[28px] leading-[36px] ">
            Point of sale terminals
          </h1>
          <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
            A secured payment app for everyone. Easy access to finances anytime
            anywhere. Through this unique channel, banking products have become
            more accessible to customers where access to Banks/ATMs is
            difficult.
          </p>
        </div>
        <div className="w-1/3 md:w-full block">
          <img
            src={Photo}
            alt="pic"
            width="310.58px"
            height="637.62px"
            className="w-full "
          />
        </div>
        <div className="w-1/3 md:w-full  flex-wrap flex flex-col justify-center md:flex-row gap-[68px] items-start">
          <div className="w-full">
            <p className="mb-[8px] font-[Sarabun] font-medium text-[#1E2C24] text-[14px] leading-[14px]">
              [ FOR BUSINESS ]
            </p>
            <h1 className="mb-[16px] font-[Sarabun]   font-normal text-[#1E2C24] text-[22px] leading-[30px] tracking-[0.02em]">
              Sayswitch agent app
            </h1>
            <p className="font-[Sarabun]  font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
              A mobile application with the ability of making financial services
              more inclusive to the unbanked population in Nigeria.
            </p>

            <li
              onClick={() => {
                history.push("/agentbanking");
                window.scrollTo(0, 0);
              }}
              className="text-[#008037] cursor-pointer mt-[20px]"
            >
              Learn more
            </li>
          </div>
          <div className="w-full">
            <p className="mb-[8px] font-[Sarabun] font-medium text-[#1E2C24] text-[14px] leading-[14px] tracking-[1px]">
              [ PERSONAL ]
            </p>
            <h1 className="mb-[16px] font-[Sarabun] font-normal text-[#1E2C24] text-[22px] leading-[30px] tracking-[0.02em]">
              Sayswitch user app
            </h1>
            <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
              A mobile platform that offers various value-added services from
              Local transfers, Airtime & Data, Internet Service Subscription,
              Cables, Electricity and many more.
            </p>

            <li
              onClick={() => {
                history.push("/mobileapp");
                window.scrollTo(0, 0);
              }}
              className="text-[#008037] cursor-pointer mt-[20px]"
            >
              Learn more
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
