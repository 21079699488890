import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const About = () => {
  return (
    <div className="w-full h-[791px] flex flex-col  px-[152px] lg:px-[70px] md:px-[30px] bg-[#151917]">
      <div className="flex   justify-start mt-[21px] xs:mt-[10px]  gap-[28px]">
        <Link to="/about">
          <li className="text-[#4CA46C] font-[sarabun] font-medium text-[16px] leading-[24px] tracking-[0.01em]">
            Overview
          </li>
        </Link>

        <Link to="/aboutcareers">
          <li className="text-[#ffffff] font-[sarabun] font-medium text-[16px] leading-[24px] tracking-[0.01em]">
            Careers
          </li>
        </Link>
      </div>
      <div className="w-full lg:mx-auto mt-[59px] xs:mt-[15px] flex flex-col justify-center items-center md:items-start">
        <h1 className="mb-[24px] md:mb-[10px] text-[#ffffff] font-bold text-[60px] md:text-[40px] text-center md:text-left md:leading-[50px] leading-[72px] tracking-[-0.025em]">
          We want businesses to thrive in any market around the world.
        </h1>
        <p className="mb-[40px] xs:mb-[10px] px-[250px] lg:px-[0px] text-center md:text-left text-[#BBC4BF] font-normal text-[18px] leading-[26px] tracking-[0.01em] ">
          SaySwitch is on a mission to build a reliable, smart transaction
          processing and switching infrastructure that simplifies digital
          payment for everyone anywhere.
        </p>
        <div className="flex md:flex-col  gap-[24px] xs:gap-[10px] ">
          <Button className="bg-[#018038] h-[56px] min-w-[239px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
            See our vision{" "}
          </Button>
          <Link to="/contactus">
            <Button className="bg-[#ffffff] h-[56px] min-w-[239px] flex text-[#008037] justify-center items-center border-none font-bold  p-[20px]">
              Contact us{" "}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
