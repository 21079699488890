import React from "react";
import GlobalPayouts from "./GlobalPayouts";
import PayContractors from "./PayContractors";
import PaymentHasNerver from "./PaymentHasNerver";
import ReadyToStart from "./ReadyToStart";
import SayswitchApi from "./SayswitchApi";

const CollectionAndDisb = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <PaymentHasNerver />
      <PayContractors />
      <GlobalPayouts />
      <SayswitchApi />
      <ReadyToStart />
    </div>
  );
};

export default CollectionAndDisb;
