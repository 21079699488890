import React from "react";
import woman2 from "../assets/images/cropped-shot-beautiful-african-american-woman-with-happy-look-holds-modern-cell-phone-credit-card-makes-shopping-online 1.png";
import icon from "../assets/icons/ICONSpass.png";
import frame from "../assets/icons/Frame it1.png";
import frame2 from "../assets/icons/Arrow b.png";
import frame3 from "../assets/icons/stacked.png";
import frame4 from "../assets/icons/envelo.png";
import frame5 from "../assets/icons/phone.png";
import frame6 from "../assets/icons/thrash.png";
import frame7 from "../assets/icons/gift.png";

const ValueAddedService = () => {
  return (
    <div className="bg-[#ffffff] flex flex-col gap-[64px] lg:gap-3 w-full pt-[120px] lg:pt-[70px] md:pt-[50px] px-[152px] lg:px-[70px] md:px-[30px] ">
      <div>
        <h1 className=" font-[sarabun] text-[#1E2C24] font-bold text-[36px] leading-[44px] tracking-[0.02em]">
          Value Added Services
        </h1>
        <p className="mt-[24px] pr-[30%] lg:pr-0 font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
          SaySwitch VAS is an easy-to-use channel designed for smooth customer
          experience specializing in the distribution of digital products and
          services via a single point API connection. Our VAS platform connects
          financial institution, aggregators, and users to over 5000 billers.
          Our key product offerings include;
        </p>
      </div>
      <div className="w-full bg-[#ffffff] flex flex-row-reverse justify-center lg:flex-col rounded-lg">
        <div className=" flex flex-col  justify-center ml-[150px] lg:m-0 py-[56px] lg:py-0 w-1/2 lg:w-full  ">
          <h1 className="mt-[32px] font-[sarabun] text-left  text-[#1E2C24] font-bold text-[18px] leading-[26px] tracking-[0.02em] ">
            Key Features
          </h1>
          <div className="mt-[20px]  font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            <li className="flex justify-start mt-5 gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              High-performance transaction switching service that ensures
              connections to Visa and Mastercard networks.
            </li>
            <li className="flex justify-start mt-5  gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Our gateway service facilitates the dynamic switching and
              processing of a wide range of transaction types originating from
              multiple channels across different payment networks.
            </li>
            <li className="flex justify-start mt-5  gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              We work with you to extend this service to accommodate a variety
              of local networks so you can participate in wider payment
              ecosystems.
            </li>
          </div>
        </div>
        <div className=" flex justify-center py-[56px] w-1/2 lg:w-full ">
          <img
            src={woman2}
            alt=""
            width="582px"
            height="482px"
            className=" rounded-[8px] lg:w-full"
          />
        </div>
      </div>
      <div className="w-full grid grid-cols-4 grid-flow-row justify-items-center lg:grid-cols-2  grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[44px] md:gap-[0px] md:gap-y-[24px] md:align-center ">
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px]  border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              E-Vouchers
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              We supply a broad range of e-vouchers for the World's leading
              brands, supporting your business to grow its product portfolio
              and/or offer incentives and rewards to staff and customers
              digitally.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame2}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Airtime Top Ups
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              Providing end-users with direct access to top up themselves or 3rd
              parties in domestic markets. Empowering international migrants and
              globally mobile end-users to pay locally and top up, ensuring they
              are always connected.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame3}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Bulk Data
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              We provide businesses with unique data solutions. This may be to
              keep their staff connected, their digital assets online or give
              them the ability to sponsor data for their digital customers to
              interact with their brand.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame4}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              SMS Services
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              Our SMS platform enables our partners to send bulk SMS to their
              customers for marketing, transaction completions and/or
              verification purposes.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame5}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Mobile Data Top Ups & Data Plans
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              Providing mobile data top up plans, without the hassle of first
              topping up airtime and dialling operator specific data activation
              codes.
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame6}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Lifestyle, Utility and Bill Payments
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              Providing access to digital products and services including TV
              subscriptions, electricity payments, food vouchers and a range of
              other value propositions from over 150 countries worldwide.
            </p>
          </div>
        </div>

        <div className="bg-[#ffffff]  xl:mb-[24px] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className=" ">
            <img
              src={frame7}
              alt=""
              width="48px"
              height="48px"
              className="mb-[24px] rounded-[3.43px]"
            />
            <h5 className="mb-[8px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Rewards and Incentive Programs
            </h5>
            <p className="font-normal text-[14px] lg:gap-r-0 leading-[20px] tracking-[0.02em] text-[#3B443F]">
              We enable our partners to achieve their goals by using digital
              offerings like mobile data as an incentive to help drive specific
              outcomes such as customer acquisition, product adoption, usage or
              loyalty rewards.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueAddedService;
