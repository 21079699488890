import React from "react";
import Photo from "../assets/images/african-american-business-woman-by-window (1) 1.png";

function SocialResponsibility() {
  return (
    <div
      id="vision"
      className="container  space-y-8 flex 
     md:p-[30px] md:mt-[-20px] gap-12 flex-row 
     md:flex-col-reverse md:items-center "
    >
      <div className="w-1/2 md:w-full my-auto">
        <h1 className="font-[sarabun] font-bold text-[36px] leading-[44px] mb-[20px]">
          Social responsibility
        </h1>
        <p className="font-[Sarabun] font-normal text-[#3B443F] text-[18px] leading-[26px] tracking-[-0.01em] mb-[32px]">
          Sayswitch prioritizes building an ethical
          <br /> business
        </p>
        <div className="flex flex-col w-[374px] xl:w-full mt-[32px]  gap-[8px]">
          <h1 className="font-[Sarabun] font-bold text-[#3B443F] text-[18px] leading-[26px] tracking-[-0.02em]">
            Our place in the society
          </h1>
          <p className="font-[Sarabun] font-normal text-[#3B443F] text-[18px] leading-[26px] tracking-[-0.01em]">
            For our position in society, it was a wise decision to make choices
            that would help us build an ethical business while also driving
            growth for our merchants.
          </p>
        </div>
      </div>
      <div className="w-1/2 md:w-full block ">
        <img
          src={Photo}
          alt="pic"
          width="554px"
          height="556px"
          className="w-full  rounded-[10px]"
        />
      </div>
    </div>
  );
}

export default SocialResponsibility;
