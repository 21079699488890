import React from "react";
import PartnerWithUs from "./PartnerWithUs";
import PartnerWithUsBox from "./PartnerWithUsBox";
import TheSponsors from "./TheSponsors";
import WhyChooseUs from "./WhyChooseUs";
import WithPartnership from "./WithPartnership";

const Partners = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <PartnerWithUs />
      <TheSponsors />
      <WhyChooseUs />
      <WithPartnership />
      <PartnerWithUsBox />
    </div>
  );
};

export default Partners;
