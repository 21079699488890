import React from "react";
import Partners from "../components/Partners";

function PartnersView() {
  return (
    <div className="w-full max-w-[1728px] m-auto scrollbar-hide relative">
      <Partners />
    </div>
  );
}

export default PartnersView;
