import React from "react";

const PandCwhatWeUse = () => {
  return (
    <>
      We will use this information to:
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Administer your accounts with us
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Process orders or applications submitted by you
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Send you information about our products and services
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Carry out marketing analysis and make general improvements to our site
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Obtain your views or comments on the services we provide
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Respond to your inquiries and fulfil your requests
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Inform you about important information regarding the Site, products or
          services for which you apply or may be interested in applying for, or
          in which you are already enrolled, changes to terms, conditions, and
          policies and/or other administrative information.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Deliver marketing communications that we believe may be of interest to
          you, including, ads or offers tailored to you.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Allow you to apply for products or services.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Verify your identity and/or location, to allow access to your
          accounts, conduct online transactions and to maintain measures aimed
          at preventing fraud and protecting the security of the account and
          personal information.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Allow you to participate in surveys and other forms of market
          research, sweepstakes, contests and similar promotions and to
          administer these activities. Some of these activities have additional
          rules, which may contain additional information about how personal
          information is used and shared.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Business purposes, including data analysis, audits, developing and
          improving products and services, enhancing the Site, identifying usage
          trends, and determining the effectiveness of promotional campaigns.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          For risk control, for fraud detection and prevention, to comply with
          laws and regulations, and to comply with other legal process and law
          enforcement requirement.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Allow you to utilize features within our Sites by granting us access
          to information from your device such as contact lists, or geo-location
          when you request certain services.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch shall use personal information to provide customers services
          and products.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Based on the request of Governmental compliance bodies, our
          regulators, and judicial bodies Sayswitch may disclose personal
          information.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch may also use personal information to advertise our products
          and offerings to you.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch will also use your information to detect abnormalities or
          fraudulent activities on our system.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch only stores data for the purpose which it was collected for.
          The retention period for Storage of data is also largely determined by
          Legal and Regulatory bodies.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Finally, we may contact you as necessary to enforce our policies,
          applicable law, or any agreement we may have with you.
        </li>
      </ol>
    </>
  );
};

export default PandCwhatWeUse;
