import React from "react";

const PandCdisclaimers = () => {
  return (
    <>
      WE TRY TO KEEP SAYSWITCH AVAILABLE AT ALL TIMES, BUG-FREE AND SAFE,
      HOWEVER, YOU USE IT AT YOUR OWN RISK. OUR WEBSITE AND SERVICES ARE
      PROVIDED “AS IS” WITHOUT ANY EXPRESS,IMPLIED AND/OR STATUTORY WARRANTIES
      (INCLUDING, BUT NOT LIMITED TO,ANY IMPLIED OR STATUTORY WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND
      NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS). WITHOUT LIMITING THE
      GENERALITY OF THE FOREGOING, SAYSWITCH MAKES NO WARRANTY THAT OUR WEBSITE
      AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL BE
      UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. NO ADVICE OR INFORMATION,
      WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH OUR WEBSITE OR FROM
      SAYSWITCH, ITS PARENTS, SUBSIDIARIES, OR OTHER AFFILIATED COMPANIES, OR
      IT’S OR THEIR SUPPLIERS (OR THE RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
      OR AGENTS OF ANY SUCH ENTITIES) (COLLECTIVELY,"SAYSWITCH PARTIES") SHALL
      CREATE ANY WARRANTY.
    </>
  );
};

export default PandCdisclaimers;
