import React from "react";
import { Link } from "react-router-dom";
import photo from "../assets/images/Contact.png";
import group from "../assets/images/Group 626264.png";
import phone from "../assets/images/Payment integration 1.png";
import Button from "./Button";

const TheBestWay = () => {
  return (
    <div className="w-full ">
      <div className="w-full mx-auto mt-[72px] lg:px-[70px] md:px-[30px] flex flex-col justify-start items-center md:items-start ">
        <img src={photo} alt="" />
        <h1 className="font-[sarabun] mt-[12px] mb-[32px] text-center md:text-left font-bold text-[60px] leading-[72px] ">
          The best way to collect
          <br /> money globally.
        </h1>
        <p className="font-[sarabun] px-[420px] md:text-left xl:px-[200px] lg:px-[0] text-center text-[18px] leading-[26px]  tracking-[0.002em]">
          One platform that allows you choose how best your customers can make
          payment or receive payments -- online, anywhere in the country, and
          everywhere in-between
        </p>
        <div className="mb-[110px] flex md:flex-col md:items-start gap-[24px] mt-[40px]">
          <Link to="contactus">
            <Button className="w-[203px] h-[56px] bg-[#008037] text-[#ffffff]">
              Contact sales
            </Button>
          </Link>
          {/* <Link>
            <Button className="w-[203px] h-[56px] bg-[#ffffff] text-[#008037]">
              Contact Sales
            </Button>
          </Link> */}
        </div>
      </div>
      <div className="w-full border-box lg:hidden">
        <div className="w-full flex h-[300px] ">
          <div className="w-1/2 ">
            <div className="h-[145px] w-full bg-[#ffffff]"></div>
            <div className="h-[155px] w-full bg-[#ECF3F9]"></div>
          </div>
          <div className="w-1/2 bg-[#ECF3F9]"></div>
        </div>
        <img src={group} alt="" className="mt-[-390px]" />
        <div className="w-full h-[370px]  bg-[#ECF3F9]"></div>
        <div className="w-full px-[153px] mt-[-650px] flex xl:justify-center">
          <img
            src={phone}
            alt=""
            className="  max-h-[650px] max-w-[1074.25px]"
          />
        </div>
      </div>
    </div>
  );
};

export default TheBestWay;
