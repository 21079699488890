import React from "react";
import Button from "./Button";
import photo from "../assets/images/Contact-checkout.png";
import photo2 from "../assets/images/Dashboard-checkout.png";
import photo3 from "../assets/images/dashboardcheck3.png";
import { Link } from "react-router-dom";

function SecureAndModern() {
  return (
    <div className="w-full flex  mb-[132px] lg:mb-[0px] lg:flex-col-reverse pl-[152px] xl:pl-[30px] ">
      <div className="w-1/2 my-auto lg:w-full pr-[90px] xl:pr-[0px] lg:pr-[30px] flex flex-col items-start ">
        <img src={photo} alt="" className=" " />
        <h1 className="font-[sarabun] mt-[16px] text-[#1E2C24] text-left font-bold text-[48px] leading-[56px]  ">
          Secure and modern solution for accepting payments
        </h1>
        <p className="font-[sarabun] mt-[24px] text-[#3B443F] text-left text-[18px] leading-[26px] tracking-[0.002em]">
          We Strive to create value for our Partners and their merchants, no
          matter what solution and features you choose you can accept payments
          anywhere.
        </p>
        <div className="mt-[40px] flex justify-start ">
        <Link to="/contactus">
          <Button className="w-[203px] h-[56px] bg-[#008037] text-[#ffffff] border-none ">
            Contact sales
          </Button>
        </Link>
        </div>
      </div>
      <div className="w-1/2  lg:w-full flex justify-end lg:justify-end  md:hidden">
        <img
          src={photo3}
          alt=""
          className="max-h-[593.09px] max-w-[288.89px] mr-[-170px] mt-[90px] z-[10]"
        />
        <img src={photo2} alt="" className="max-h-[588px] max-w-[940.8px]" />
      </div>
    </div>
  );
}

export default SecureAndModern;
