import React from "react";

const PandCookies = () => {
  return (
    <>
      When you visit the site we may store some information (commonly known as a
      "cookies") on your computer. Cookies are small files of information that
      use a unique identification tag and are stored on your device as a result
      of using the Site or other services we provide to you. Several cookies we
      use last only for the duration of your session and expire when you close
      your browser. Other cookies are used to remember you when you return to
      the site and will last for longer. A cookie helps you get the best out of
      the Site and helps us to provide you with a more customized and tailored
      service. We use cookies to:
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          So that you will not have to re-enter your details each time you visit
          the Site
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          To track how our Site is used and to improve and update our content.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Store your preferences.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Customize elements of the layout and/or content of the site for you
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Collect statistical information about how you use the site so that we
          can improve the site.
        </li>
      </ol>
      You can refuse to accept these cookies and most devices and browsers offer
      their privacy settings for cookies. You will need to manage your cookie
      settings for each device and browser you use. However, if you do not
      accept these cookies, you may experience some inconvenience in your use of
      the Site and some online products and services. For example, we will not
      be able to recognize your device and you will need to answer a challenge
      question each time you log on. You also may not receive tailored
      advertising or other offers from us that may be relevant to your interests
      and needs.
    </>
  );
};

export default PandCookies;
