import React from "react";
import AboutOverview from "../components/AboutOverview";
import AboutPartners from "../components/AboutPartners";
import OurBrand from "../components/OurBrand";
import OurVision from "../components/OurVision";
import ReadyToStart from "../components/ReadyToStart";

const AboutView = ({ value, setValue }) => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <AboutOverview />
      <AboutPartners />
      <OurVision value={value} setValue={setValue} />
      <OurBrand />
      <ReadyToStart />
    </div>
  );
};

export default AboutView;
