import React from "react";

const PandCaccountSecurity = () => {
  return (
    <>
      You agree not to allow anyone else to have or use your password details
      and to comply with all reasonable instructions we may issue regarding
      account access and security. In the event you share your password details,
      Sayswitch will not be liable to you for losses or damages. You will also
      take all reasonable steps to protect the security of the personal
      electronic device through which you access Sayswitch’s services
      (including, without limitation, using PIN and/or password protected
      personally configured device functionality to access Sayswitch’s services
      and not sharing your device with other people).
    </>
  );
};

export default PandCaccountSecurity;
