import React from "react";
import Icon from "../assets/images/Featured icon1.png";
import Icon2 from "../assets/images/Featured icon2.png";
import Icon3 from "../assets/images/Featured icon3.png";

const FullyCertified = () => {
  return (
    <div className="w-full min-h-[1010px] mb-[120px] py-[156px] flex flex-col px-[152px]  lg:px-[70px] md:px-[30px] bg-[#F3F6F9]">
      <div className="w-full mb-[96px]">
        <h1 className="font-[Sarabun] font-bold text-[36px] leading-[44px] tracking-[0.04em] ">
          Fully certified terminals for any
          <br /> business need.
        </h1>
      </div>
      <div className="w-full grid grid-cols-3 grid-flow-row justify-items-center lg:grid-cols-2  grid-rows-1 lg:grid-rows-2 md:grid-cols-1 gap-[24px] md:gap-[0px] md:gap-y-[24px] md:align-center ">
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px]">
            <img src={Icon} alt="" className="mb-[72px] " />
            <h5 className="mb-[12px]  font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Fully configured terminals
            </h5>
            <p className="font-normal text-[16px] lg:gap-r-0 leading-[24px] tracking-[0.02em] text-[#3B443F]">
              SaySwitch POS application can be set-up and fully configured
              remotely which allows faster deployment to merchant locations. Get
              the free SaySwitch POS that works seamlessly with our range of
              hardware options
            </p>
          </div>
        </div>
        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img src={Icon2} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Flexible terminals
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Our terminals are flexible, portable, and powerful solutions
              capable of doing business anywhere. It accepts chip & PIN, or
              contactless payments. Print receipts easily and quickly
            </p>
          </div>
        </div>

        <div className="bg-[#ffffff] max-w-[363px] min-h-[362px] border-1 rounded-[10px] ">
          <div className="px-[24px] py-[40px] lg:w-full ">
            <img src={Icon3} alt="" className="mb-[72px]" />
            <h5 className="mb-[12px] font-[sarabun] font-bold text-[22px] leading-[30px] tracking-[0.02em]  text-[#1E2C24] ">
              Secure and Scalable
            </h5>
            <p className="font-normal text-[16px] leading-[24px] tracking-[0.02em] text-[#3B443F]">
              Security standards using encryption to offer a safe and
              friction-free experience for your customers. Every device comes
              embedded with enhanced security keeping your cardholder data
              protected.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullyCertified;
