import React from "react";
import guy2 from "../assets/images/easy-wireless-yechnology-payment 1.png";
import photo from "../assets/images/portrait-smiling-african-american-businessman-white-shirt-eyewear-sitting-working-his-laptop-office-isolated 1.png";
import woman from "../assets/images/Payment remove bg 1.png";
import guy from "../assets/images/pexels-tima-miroshnichenko-5198239 2.png";

const ServicesAndAddedValue = () => {
  return (
    <div className="bg-[#EEF2F6] mt-[-550px] xl:mt-[-500px] xl:mt-[-450px]  lg:mt-[-400px] md:mt-[-370px] flex flex-col gap-[64px] w-full pb-[156px] pt-[456px] lg:pt-[300px] md:pt-[200px] px-[152px] lg:px-[70px] md:px-[30px] ">
      <h3 className="mb-[32px] text-center  font-[sarabun] font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[-0.02em]">
        Services and added value
      </h3>

      <div className="flex gap-[64px] lg:flex-col ">
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full flex flex-col p-[40px] rounded-[8px]">
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Reporting
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            Access reporting tools for monitoring your transactions real time
            through our web-based user interface or utilize our REST API to
            build your own reporting tools. Refund transactions with a click of
            a button.
          </p>
          <img
            src={photo}
            alt=""
            width="626.05px"
            height="354.34px"
            className="mt-[88px] lg:mt-[40px] rounded-[8px] lg:w-full"
          />
        </div>
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full  flex flex-col p-[40px] rounded-[8px]">
          <img
            src={woman}
            alt=""
            width="498px"
            height="332px"
            className="mb-[68px] rounded-[8px] lg:w-full"
          />
          <h1 className="font-[sarabun] xl:text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Settlement
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            A Single Settlement daily. A full overview of all your transaction
            fees and payout. Reconcile and match transactions, dispute, and
            refunds effortlessly. Quicker settlements and payouts
          </p>
        </div>
      </div>
      <div className="flex gap-[64px] lg:flex-col ">
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full  flex flex-col p-[40px] rounded-[8px]">
          <img
            src={guy}
            alt=""
            width="498px"
            height="332px"
            className="mb-[68px] rounded-[8px] lg:w-full"
          />
          <h1 className="font-[sarabun] xl:text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            White label branding
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            A service where any business has the possibility to white label the
            SaySwitch Services to their merchants including their own setup on
            the SaySwitch Gateway.
          </p>
        </div>
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full flex flex-col p-[40px] rounded-[8px]">
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[36px] leading-[44px] ">
            Payment processing
          </h1>
          <p className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] tracking-[0.02em]">
            Save on fees and increase efficiency when you bring everything
            together with SaySwitch gateway. Become more integrated and cohesive
            moving forward in an omnichannel world, both online and in-person.
          </p>
          <img
            src={guy2}
            alt=""
            width="626.05px"
            height="354.34px"
            className="mt-[88px] lg:mt-[40px] rounded-[8px] lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesAndAddedValue;
