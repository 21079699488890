import React from "react";
import Button from "./Button";
import store2 from "../assets/images/Contentleft.png";
import store from "../assets/images/Contentright.png";

const DownloadSayswitch = () => {
  return (
    <div className="w-full  bg-[#ffffff] px-[152px] py-[120px] lg:py-[60px] lg:px-[40px] md:px-[0px]">
      <div className="w-full  flex  lg:flex:col px-[150px] xl:px-[30px] lg:px-[100px] md:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
        <div className="w-full py-[72px] mx-auto  lg:mx-auto flex flex-col justify-center align-center md:items-start items-center ">
          <h1 className="mb-[24px] text-[#ffffff] text-center md:text-left font-bold text-[18px] leading-[26px] tracking-[0.01em]">
            Get started
          </h1>
          <p className="font-bold text-[36px] text-center md:text-left  leading-[44px] tracking-[-0.001em] mb-[40px] text-[#ffffff]  ">
            Download the Sayswitch app on your phone and get started
          </p>
          <div className="mt-[40px] flex md:flex-col md:items-start gap-[24px] ">
            <Button className="w-[190px] h-[54px] bg-[#008037] border-[#008037] ">
              <span className="flex gap-[13.78px] items-center  justify-center">
                <img src={store2} alt="logo" />
              </span>
            </Button>
            <Button className="w-[190px] h-[54px] bg-[#008037] border-[#008037]  ">
              <span className="flex gap-[13.78px] items-center justify-center">
                <img src={store} alt="" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadSayswitch;
