import React from "react";
import Button from "./Button";
import photo from "../assets/images/saycollect.png";
import photo2 from "../assets/images/Dashboard 6.png";
import { Link } from "react-router-dom";

const PaymentHasNerver = () => {
  return (
    <div className="w-full flex mb-[120px] lg:mb-[0px] lg:flex-col-reverse pl-[152px] xl:pl-[0px]:xl:px-[152px] lg:px-[70px] md:px-[30px]">
      <div className="w-1/2 lg:w-full pr-[90px] xl:pr-[0px] flex flex-col items-start ">
        <img
          src={photo}
          alt=""
          width="152px"
          height="24px"
          className="mt-[91px] lg:mt-[40px] "
        />
        <h1 className="font-[sarabun] mt-[16px] text-[#1E2C24] text-left font-bold text-[48px] leading-[56px]  ">
          Payments has never been faster
        </h1>
        <p className="font-[sarabun] mt-[24px] text-[#3B443F] text-left text-[18px] leading-[26px] tracking-[0.002em]">
          SaySwitch Collect is a collection and disbursement, monitoring and
          reporting solution that helps organizations receive payments from
          their customers across various channels, including banks, SaySwitch
          POS and SaySwitch Payment Gateway and payout to their partners.
        </p>
        <div className="mt-[40px] lg:mb-[40px] flex justify-start gap-[24px] md:gap-3 md:flex-col">
          <Link to="/contactus">
            <Button className="w-[203px] h-[56px] bg-[#008037] text-[#ffffff] border-none ">
              Contact sales
            </Button>
          </Link>
          {/* <Link>
            <Button className="w-[203px] h-[56px] border-[#008037] text-[#008037] ">
              Contact Sales
            </Button>
          </Link> */}
        </div>
      </div>
      <div className="w-1/2 lg:w-full flex justify-end lg:justify-center mt-[26px]">
        <img src={photo2} alt="" width="924.04px" height="587px"/>
      </div>
    </div>
  );
};

export default PaymentHasNerver;
