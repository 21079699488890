import React, { useState } from "react";
import Button from "./Button";

const ContactUs = () => {
  const [status, setStatus] = useState(false);
  const handleClick = () => {
    setStatus(true);
  };
  const [solution, setSolution] = useState(false);
  const handleSolution = () => {
    setSolution(true);
  };
  const [country, setCountry] = useState(false);
  const handleCountry = () => {
    setCountry(true);
  };
  const [industry, setIndustry] = useState(false);
  const handleIndustry = () => {
    setIndustry(true);
  };

  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <div className="w-full flex lg:flex-col px-[152px] lg:px-[70px] md:px-[30px] mt-[70px] mb-[120px]">
        <div className="w-1/2 lg:w-full">
          <h1 className="mb-[32px] text-[#1E2C24] font-[sarabun] tracking-normal font-bold text-[60px] xl:text-[50px] lg:text-[40px] md:text-[30px] leading-[72px] xl:leading-[62px] lg:leading-[52px] md:leading-[42px] ">
            How can we help?
          </h1>
          <p className="mb-[20px] text-[#3B443F] font-[sarabun] font-normal text-[18px] leading-[26px] track-[0.02em] ">
            We remove the limits of innovation by supporting business growth in
            terms of customer experience, products, services, infrastructure,
            and strategic vision.
          </p>
          <h5 className="mb-[4px] text-[#3B443F] font-[sarabun] font-bold text-[18px] leading-[26px] track-[0.02em]">
            Office address
          </h5>
          <p className="text-[#008037] font-[sarabun] font-normal text-[18px] leading-[26px] track-[0.02em]">
            954A, Akarigbere close, off idejo street, Adeola odeku, Victoria island, Lagos
          </p>
        </div>
        <form className="w-1/2 lg:w-full mt-[20px]">
          <div className="  flex flex-col space-y-[20px] items-center lg:items-start ">
            <label class="relative form-control block  w-[417px]  md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                className="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none focus:border-green-600 focus:ring-green-600 "
                placeholder="First name"
                type="text"
                name="first_name"
                id="first_name"
                required
              />
              <label
                for="first_name"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5  text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500 required "
              >
                First name
              </label>
            </label>
            <label className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600  "
                placeholder="Last name"
                type="text"
                name="last_name"
                id="last_name"
                required
              />
              <label
                for="last_name"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5  text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
              >
                Last name
              </label>
            </label>
            <label className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600  "
                placeholder="Business email"
                type="text"
                name="email"
                id="email"
                required
              />
              <label
                for="email"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5  text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
              >
                Business email
              </label>
            </label>
            <label className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600  "
                placeholder="Last name"
                type="number"
                name="phone"
                id="phone"
                required
              />
              <label
                for="phone"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5  text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
              >
                Phone number
              </label>
            </label>
            <label className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600  "
                placeholder="Business name"
                type="text"
                name="business_name"
                id="business_name"
                required
              />
              <label
                for="business_name"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5  text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
              >
                Business name
              </label>
            </label>
            <div className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <select
                onClick={() => handleIndustry()}
                class="peer font-[sarabun] m-0 form-control bg-white bg-no-repeat  xs:text-base bg-clip-padding font-normal text-[16px] sm:text-base option-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600 "
                aria-label="industry"
                name="industry"
                id="industry"
                required
              >
                <option selected>{industry && "Industry"}</option>
                <option value="1">Retail & eCommerce</option>
                <option value="3">Telecommunication</option>
              </select>

              <label
                for="industry"
                className={`transition-all m-[16px] lg:m-[8px] absolute ${!industry
                    ? "top-0 left-0  lg:top-3"
                    : "left-0 -top-4 lg:-top-2 text-gray-400 text-xs"
                  }   text-gray-500 peer:text-sm peer-option-shown:top-0 peer-focus:-top-4 sm:text-xs lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-option-shown:top-2 peer-option-shown:text-base sm:peer-option-shown:text-xs peer-option-shown:text-gray-500`}
              >
                Industry
              </label>
            </div>
            <label className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <input
                class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600 "
                placeholder="Business website / Social media link"
                type="text"
                name="website_social_name"
                id="website_social_name"
              />
              <label
                for="website_social_name"
                className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2.5 text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
              >
                Business website / Social media link
              </label>
            </label>
            <div className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <select
                onClick={() => handleCountry()}
                class="peer font-[sarabun] m-0 form-control bg-white bg-no-repeat  xs:text-base bg-clip-padding font-normal text-[16px] sm:text-base option-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600 "
                aria-label="country"
                name="country"
                id="country"
              >
                <option selected>{country && "Country"}</option>
                <option value="1">Nigeria</option>
                <option value="3">United Kingdom</option>
              </select>

              <label
                for="country"
                className={`transition-all m-[16px] lg:m-[8px] absolute 
                ${!country
                    ? "top-0 left-0  lg:top-3"
                    : "left-0 -top-4 lg:-top-2 text-gray-400 text-xs"
                  }   text-gray-500 peer:text-sm peer-option-shown:top-0 peer-focus:-top-4 sm:text-xs lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-option-shown:top-2 peer-option-shown:text-base sm:peer-option-shown:text-xs peer-option-shown:text-gray-500`}
              >
                Country
              </label>
            </div>
            <div className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <select
                onClick={() => handleClick()}
                class="peer font-[sarabun] m-0 form-control bg-white bg-no-repeat  xs:text-base bg-clip-padding font-normal text-[16px] sm:text-base option-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600 "
                aria-label="employees"
                name="employees"
                id="employees"
              >
                <option selected>{status && "Number of employees"}</option>
                <option value="1">Under 10 employees</option>
                <option value="3">Above 50 employees</option>
              </select>

              <label
                for="employees"
                className={`transition-all m-[16px] lg:m-[8px]  absolute ${!status
                    ? "top-0 left-0  lg:top-3"
                    : "left-0 -top-4 lg:-top-2 text-gray-400 pl-[1px] text-xs"
                  }   text-gray-500 peer:text-sm  peer-option-shown:top-0 peer-focus:-top-4 sm:text-xs lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-option-shown:top-2 peer-option-shown:text-base sm:peer-option-shown:text-xs peer-option-shown:text-gray-500`}
              >
                Number of employees
              </label>
            </div>
            <div className="relative block w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <select
                onClick={() => handleSolution()}
                class="peer font-[sarabun] m-0 form-control bg-white bg-no-repeat  xs:text-base bg-clip-padding font-normal text-[16px] sm:text-base option-transparent sm:placeholder:text-sm leading-[24px]  tracking-[0.02em]  w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none  focus:border-green-600 focus:ring-green-600 "
                aria-label="what_solution"
                name="what_solution"
                id="what_solution"
              >
                <option selected>
                  {solution && " What solution are you looking for?"}
                </option>
                <option value="1">Point Of Sales (POS) machine</option>
                <option value="3">Others</option>
              </select>

              <label
                for="what_solution"
                className={`transition-all m-[16px] lg:m-[8px]  absolute ${!solution
                    ? "top-0 left-0  lg:top-3"
                    : "left-0 -top-4 lg:-top-2 text-gray-400 text-xs"
                  }   text-gray-500 peer:text-sm peer-option-shown:top-0 peer-focus:-top-4 sm:text-xs lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-option-shown:top-2 peer-option-shown:text-base sm:peer-option-shown:text-xs peer-option-shown:text-gray-500`}
              >
                What solution are you looking for?
              </label>
            </div>
            <label className="relative flex flex-col w-[417px] md:w-[350px] sm:w-[300px] xs:w-[260px]">
              <div class="form-group mb-6">
                <textarea
                  class="peer font-[sarabun] m-0 form-control bg-white bg-clip-padding font-normal text-[16px] sm:text-base placeholder-transparent sm:placeholder:text-sm leading-[24px] tracking-[0.02em] w-full border border-slate-300 rounded-md p-[16px] lg:px-[8px] shadow-sm focus:outline-none focus:border-green-600 focus:ring-green-600  "
                  id="tell_us"
                  rows="6"
                  placeholder="Tell us more about your business"
                ></textarea>
                <label
                  for="tell_us"
                  className="transition-all m-[16px] lg:m-[8px] absolute left-0 -top-4 lg:-top-2 text-gray-400 text-xs peer-placeholder-shown:top-0 peer-focus:-top-4 lg:peer-focus:-top-2 peer-focus:text-gray-400 peer-focus:text-xs lg:peer-placeholder-shown:top-2 peer-placeholder-shown:text-base sm:peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-500"
                >
                  Tell us more about your business
                </label>
              </div>
            </label>
            <Button
              type="submit"
              className="w-[417px]  md:w-[350px] sm:w-[300px] xs:w-[260px] mt-[36px] h-[56px] bg-[#008037] text-[#ffffff]"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;

