import React from "react";

const PandCtheInformation = () => {
  return (
    <>
      We will collect the following information from you:
      <ol className="ml-[18px]">
        <li className="list-decimal my-[16px]  list-outside  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Name, a username, gender, date of birth
        </li>
        <li className="list-decimal list-outside  mb-[16px]  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Email address, postal address, telephone number Your interests.
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Account number, credit card number and expiry date
        </li>
        <li className="list-decimal list-outside  mb-[16px]  font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Merchant and acquirer Information
        </li>
      </ol>
      Although it is not compulsory to give us this information if you do not
      then we may not be able to provide you with the full range of services
      that the Site has to offer. Information is collected electronically and
      manually when they visit our website and register to use any of our
      services. Information is collected electronically with exchanges between
      your system or service provider's system and our system. We collect your
      information manually when you complete our product and services
      registration forms in registering to use any of our services. Similar
      information is also collected when customers or visitors' visit our
      physical locations for inquiries or business relationship.
    </>
  );
};

export default PandCtheInformation;
