import React from "react";
import Image1 from "../assets/images/Shape.png";
import Image2 from "../assets/images/Vector-3.png";
import Image3 from "../assets/images/Zenith-Bank-900x0 1.png";
import Image4 from "../assets/images/1110px-Verifone_Logo 1.png";
import Image5 from "../assets/images/Wema bank.png";
import Image6 from "../assets/images/black-logo 1.png";

function Homepage2() {
  return (
    <div className="w-full flex flex-col px-[152px] lg:px-[70px] md:px-[30px] pb-[60px] space-y-12 ">
      <div className=" relative overflow-x-hidden px-[0px] z-10 h-[100px] pt-[36px]">
        <div className=" flex items-start space-x-20 animateImage">
          <img src={Image1} alt="" />
          <img src={Image2} alt="" />
          <img src={Image3} alt="" />
          <img src={Image4} alt="" />
          <img src={Image5} alt="" />
          <img src={Image6} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Homepage2;
