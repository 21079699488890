import React from "react";

const PandCdisputeAnd = () => {
  return (
    <>
      If you believe that an unauthorized or otherwise problematic transaction
      has taken place, you agree to notify us immediately, to enable us take
      action to help prevent financial loss. All claims against us related to
      payments should be made within 45 (forty-five) days after the date of such
      payment. It will be taken that you waive all claims against us, to the
      fullest extent of the law after the said period of time. If you enter a
      transaction with a third party and have a dispute over the goods or
      services you purchased, we have no liability for such goods or services.
      Our only involvement with regard to such transaction is as a payment
      gateway. We may intervene in disputes between users and merchants
      concerning payments but have no obligation to do so. Your transaction ID
      and/or transaction details will be required to resolve all disputes.
    </>
  );
};

export default PandCdisputeAnd;
