import React from "react";
import CompleteFeature from "../components/CompleteFeature";
import TheSponsors from "../components/TheSponsors";
import CardSection from "../components/CardSection";
import AcceptPayment from "../components/AcceptPayment";
import PosTerminal from "../components/PosTerminal";
import MobileApp from "../components/MobileApp";
import Insights from "../components/Insights";
import ReadyToStart from "../components/ReadyToStart";

function HomeView() {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <CompleteFeature />
      <TheSponsors />
      <CardSection />
      <AcceptPayment />
      <PosTerminal />
      <MobileApp />
      <Insights />
      <ReadyToStart />
    </div>
  );
}

export default HomeView;
