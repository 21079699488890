import React from "react";

const PandCcertainBusinessCategory = () => {
  return (
    <>
      You may not use Sayswitch’s portal in connection with any product,
      service, transaction or activity that:
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Falls within the Prohibition List of the Nigerian Customs
          Administration of the Federal Republic of Nigeria.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Relates to the sale and/or purchase of:
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Banned narcotics, steroids, certain controlled substances or other
          products that present a risk to a consumer's safety;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Blood, bodily fluids or body parts;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Burglary tools;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Counterfeit items;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Illegal drugs and drug paraphernalia;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Fireworks, destructive devices and explosives;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Identity documents, government documents, personal financial records
          or personal information (in any form, including mailing lists);
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Lottery tickets, sweepstakes entries or slot machines without the
          required license;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Offensive material or hate speech or items that promote hate,
          violence, racial intolerance, or the financial exploitation of a
          crime;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Police badges or uniforms;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Chemicals;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Recalled items;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Prohibited services;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Unlicensed financial services, stocks or other securities;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Stolen property;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Items that infringe or violate any copyright, trademark, right of
          publicity or privacy or any other proprietary right under the laws of
          any jurisdiction;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sales of currency without Bureau De Change license, certain
          cryptocurrency operators;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Obscene material or pornography;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Certain sexually oriented materials or services;
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Certain firearms, firearm parts or accessories, ammunition, weapons or
          knives; or
        </li>
        <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Any product or service that is illegal or marketed or sold in such a
          way as to create liability to Sayswitch.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Relate to transactions that:
        </li>
        <ol>
          <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
            Show the personal information of third parties in violation of
            applicable law;
          </li>
          <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
            Support pyramid or ponzi schemes, matrix programs, other "get rich
            quick" schemes or certain multi-level marketing programs;
          </li>
          <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
            Are associated with purchases of annuities or lottery contracts,
            lay-away systems, off-shore banking or transactions to finance or
            refinance debts funded by a credit card;
          </li>
          <li className="list-decimal list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
            Involve gambling, gaming and/or any other activity with an entry fee
            and a prize, including, but not limited to casino games, sports
            betting, horse or greyhound racing, lottery tickets, other ventures
            that facilitate gambling, games of skill (whether or not it is
            legally defined as a lottery) and sweepstakes unless the operator
            has obtained prior approval from ITEX and the operator and customers
            are located exclusively in jurisdictions where such activities are
            permitted by law.
          </li>
        </ol>
      </ol>
    </>
  );
};

export default PandCcertainBusinessCategory;
