import React from "react";
import { Link } from "react-router-dom";

const SubMenuOne = ({ clearHandler }) => {
  return (
    <div
      onClick={clearHandler}
      className="gap-2 flex flex-col ml-4 transition-[0.2ms] rounded-lg text-left hover:divide-white divide-slate-200 divide-y"
    >
      <Link to="/agentbanking">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Agent Banking
        </li>
      </Link>
      <Link to="/checkout">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Checkout
        </li>
      </Link>

      <Link to="/collection">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Collect platform
        </li>
      </Link>
      <Link to="/mobileapp">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Mobile App
        </li>
      </Link>
      <Link to="/products">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Payment Gateway
        </li>
      </Link>

      <Link to="/pos">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Poin-Of-Sale(POS)
        </li>
      </Link>

      <Link to="/sayprocessing">
        <li className="hover:text-[red] font-[sarabun] font-normal text-[16px] leading-[28px] hover:bg-gray-200 ease-in-out rounded-full p-3">
          Switching & Processing
        </li>
      </Link>
    </div>
  );
};

export default SubMenuOne;
