import React from "react";

const PandCreviewUpdates = () => {
  return (
    <>
      We may need to review update or amend our Privacy Policy as our technology
      evolves and as required by law. We reserve the right to make changes to
      this Privacy Policy from time to time and notify Users of material
      changes. The Privacy Policy will apply from the effective date provided on
      our website. We advise that you check this page often, referring to the
      date of the last modification on the page. If a User objects to any of the
      changes to this Privacy Policy, the User must cease using this Site, or
      terminate their account in the event an account has been created.
    </>
  );
};

export default PandCreviewUpdates;
