import React from "react";
import AccessPayment from "./AccessPayment";
import FullyCertified from "./FullyCertified";
import FullyIntegrated from "./FullyIntegrated";
import TalkToUsAboutPOS from "./TalkToUsAboutPOS";

const SayswitchPOS = () => {
  return (
    <div className="max-w-[1440px] m-auto scrollbar-hide  xl:top-[96px] lg:top-[64px] left-[0] ">
      <AccessPayment />
      <FullyCertified />
      <FullyIntegrated />
      <TalkToUsAboutPOS />
    </div>
  );
};

export default SayswitchPOS;
