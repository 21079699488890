import React from "react";
import Frame from "../assets/images/Vector 1.png";
import Photo from "../assets/images/close-up-customer-paying-by-credit-card 1.png";
import { useHistory } from "react-router-dom";

const PosTerminal = () => {
  const history = useHistory();
  return (
    <div className="w-full px-[152px] mt-[-120px] pt-[152px] bg-[#F3F6F9] flex flex-col gap-[120px] lg:px-[40px] md:px-[0px] pb-[156px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            POS Terminal
          </p>
        </div>
        <div>
          <h1 className="font-[Sarabun] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            Access payment with
            <br /> terminals and devices
          </h1>
        </div>
      </div>
      <div
        className="container  space-y-8 flex 
     md:p-[30px] md:mt-[-20px] gap-12 flex-row 
     md:flex-col-reverse md:items-center "
      >
        <div className="w-1/2 md:w-full my-auto space-y-[12px]">
          <h1 className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[28px] leading-[36px] tracking-[0.02em]">
            Point of sale terminals
          </h1>
          <p className="font-[Sarabun]  pr-[30px] font-normal text-[#1E2C24] text-[16px] leading-[24px] tracking-[0.01em]">
            SaySwitch POS application can be set-up and fully configured
            remotely which allows faster deployment to merchant locations.
            <br />
            <br /> Our applications run on many types of terminals including
            Horizon K11, Morefun, Verifone etc, and operate over many
            communication types including GPRS, Wi-Fi, Dial-Up etc. This gives
            us an option to deploy the right type of terminal to your location.
          </p>

          <li
            onClick={() => {
              history.push("/pos");
              window.scrollTo(0, 0);
            }}
            className="text-[#008037] cursor-pointer mt-[20px]"
          >
            Learn more
          </li>

          <div className="space-y-8"></div>
        </div>
        <div className="w-1/2 md:w-full block">
          <img
            src={Photo}
            alt="pic"
            width="554px"
            height="556px"
            className="w-full "
          />
        </div>
      </div>
    </div>
  );
};

export default PosTerminal;
