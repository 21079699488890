import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const PricingCards = () => {
  const history = useHistory();

  const [amount, setAmount] = useState(2000);
  const [settlement, setSettlement] = useState(1970);
  const [fees, setFees] = useState(30);

  const calculateFees = (value) => {
    const parsedAmount = parseInt(value, 10) || 0;
    const fee = parsedAmount * 0.015;
    const cappedFee = fee > 2000 ? 2000 : fee;
    const settlementAmount = parsedAmount - cappedFee;

    setAmount(parsedAmount);
    setFees(cappedFee.toFixed(2));
    setSettlement(settlementAmount.toFixed(2));
  };

  const handleNavigate = () => {
    history.push('/pos');
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    calculateFees(value);
  };

  return (
    <div className="bg-gradient-to-b from-white to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900">
            Our Pricing
          </h2>
          <p className="mt-4 text-lg sm:text-xl text-gray-600">
            Simple, transparent pricing. No hidden fees.
          </p>
        </div>
        {/* Update the grid configuration */}
        <div className="grid gap-8 grid-cols-3 md:grid-cols-1">
          {/* Local Channels */}
          <div className="bg-white rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300">
            <div className="px-6 py-8">
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900">Local Channels</h3>
              <div className="mt-4 flex items-center justify-center">
                <span className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-green-600">1.4%</span>
              </div>
              <p className="mt-2 text-sm sm:text-lg text-gray-500">Capped at 2,000 NGN</p>
              <p className="mt-4 text-sm sm:text-md text-gray-600">On all local transactions</p>
              <div className="mt-6 flex justify-center">
                <button onClick={handleNavigate} className="bg-green-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg font-semibold hover:bg-green-500 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          </div>

          {/* International */}
          <div className="bg-white rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300">
            <div className="px-6 py-8">
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900">International</h3>
              <div className="mt-4 flex items-center justify-center">
                <span className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-blue-600">3.8%</span>
              </div>
              <p className="mt-2 text-sm sm:text-lg text-gray-500">On all international transactions</p>
              <div className="mt-6 flex justify-center">
                <button onClick={handleNavigate} className="bg-blue-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg font-semibold hover:bg-blue-500 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          </div>

          {/* Terminal */}
          <div className="bg-white rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-300">
            <div className="px-6 py-8">
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900">Terminal</h3>
              <div className="mt-4 flex items-center justify-center">
                <span className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-yellow-600">0.5%</span>
              </div>
              <p className="mt-2 text-sm sm:text-lg text-gray-500">Capped at 1,000 NGN</p>
              <p className="mt-4 text-sm sm:text-md text-gray-600">On POS card transactions</p>
              <div className="mt-6 flex justify-center">
                <button onClick={handleNavigate} className="bg-yellow-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg font-semibold hover:bg-yellow-500 transition-colors duration-300">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default PricingCards;
