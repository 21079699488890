import React from "react";

const PandClimitationOfLiability = () => {
  return (
    <>
      IN NO EVENT WILL ANY OF THE SAYSWITCH PARTIES BE LIABLE FOR (A) ANY
      INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR (B)
      ANY DAMAGES WHATSOEVER IN EXCESS OF THE AMOUNT OF THE TRANSACTION OR
      TWENTY THOUSAND UNITED STATES DOLLARS (US$20,000.00) DOLLARS, WHICHEVER IS
      LESSER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF
      REVENUES, LOST PROFITS, LOSS OF GOODWILL, LOSS OF USE, BUSINESS
      INTERRUPTION, OR OTHER INTANGIBLE LOSSES), ARISING OUT OF OR IN CONNECTION
      WITH SAYSWITCH’S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION, USE,
      INABILITY TO USE, OR THE RESULTS OF USE OF SAYSWITCH’S WEBSITES OR
      SERVICES), WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT,
      STATUTE, OR ANY OTHER LEGAL THEORY.
    </>
  );
};

export default PandClimitationOfLiability;
