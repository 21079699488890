import React from "react";
import Frame from "../assets/images/Vector 1.png";
import CashDeposits from "./CashDeposits";
import BvnEnvironment from "./BvnEnvironment";
import BillsPayment from "./BillsPayment";
import InstantRefund from "./InstantRefund";

const OurAgents = () => {
  return (
    <div className="w-full px-[152px] py-[156px] flex flex-col gap-[120px] bg-[#F7F9FC] lg:px-[40px] md:px-[0px] pb-[156px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun]  font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Features
          </p>
        </div>
        <div>
          <h1 className="font-[Sarabun] text-center font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            Our agents carry out financial services in their locality on behalf
            of SaySwitch.
          </h1>
        </div>
      </div>
      <>
        <CashDeposits />
        <BvnEnvironment />
        <BillsPayment />
        <InstantRefund />
      </>
    </div>
  );
};

export default OurAgents;
