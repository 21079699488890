import React from "react";
import photo from "../assets/images/african-man-talking-phone-reading-credit-card-number-while-sitting-office 1.png";
import woman from "../assets/images/cute-small-height-african-american-girl-with-dreadlocks-wear-coloured-yellow-dress-against-atm-with-credit-card-hand 1.png";
import woman2 from "../assets/images/Frame 115560.png";
import icon from "../assets/icons/ICONSpass.png";

const IssuerSolution = () => {
  return (
    <div className="bg-[#EEF2F6] flex flex-col gap-[64px] w-full pb-[156px] pt-[120px] lg:pt-[70px] md:pt-[50px] px-[152px] lg:px-[70px] md:px-[30px] ">
      <h3 className="mb-[32px] text-center  font-[sarabun] font-extrabold text-[#1E2C24] text-[48px] leading-[56px] tracking-[-0.02em]">
        Issuer solution
      </h3>
      <div className="flex gap-[64px] lg:flex-col ">
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full flex flex-col p-[40px] rounded-[8px]">
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[28px] leading-[36px] ">
            Issuing Services
          </h1>
          <p className="mt-[16px] font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            We remove the limits of innovation by supporting business growth in
            terms of customer experience, products, services, infrastructure,
            and strategic vision.
          </p>
          <h1 className="mt-[32px] font-[sarabun] text-left text-[#1E2C24] font-bold text-[18px] leading-[26px] tracking-[0.02em] ">
            Key Features
          </h1>
          <div className="mt-[20px] font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            <li className="flex justify-start gap-5">
              <span>
                <img src={icon} alt="" className="min-w-[24px] h-[24px] " />
              </span>
              Card & tokenization
              <br /> Our issuing services support any card type, token etc. You
              can launch your own issuing products through our solution.
            </li>
          </div>
          <img
            src={photo}
            alt=""
            width="626.05px"
            height="354.34px"
            className="mt-[88px] lg:mt-[40px] rounded-[8px] lg:w-full"
          />
        </div>
        <div className="bg-[#ffffff] py-[56px] w-1/2 lg:w-full  flex flex-col p-[40px] rounded-[8px]">
          <img
            src={woman}
            alt=""
            width="498px"
            height="332px"
            className="mb-[68px] rounded-[8px] lg:w-full"
          />
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[28px] leading-[36px] ">
            ATM Acquiring Services
          </h1>
          <p className="mt-[16px] font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            Our ATM Solution enables Financial Institution to turn a network of
            cash dispensers into a secure, customer relationship tool. This
            allows banks and ATM deployers with the ability to offer more than a
            traditional self-service experience.
            <br />
            <br />
            Provide your customers with low and no touch banking experiences
            through cardless transactions, issue virtual prepaid cards and more.
          </p>
        </div>
      </div>
      <div className="w-full bg-[#ffffff] flex justify-center lg:flex-col rounded-lg">
        <div className=" flex flex-col justify-center  py-[56px] w-1/2 lg:w-full p-[40px] ">
          <h1 className=" font-[sarabun] text-left text-[#1E2C24] font-bold text-[28px] leading-[36px] ">
            Debit Card, Credit Card & Prepaid Card Solution
          </h1>
          <p className="mt-[16px] font-[sarabun] text-[#3B443F] font-normal text-[16px] leading-[24px] tracking-[0.02em]">
            We offer a centralized cost-effective, easily configurable solution
            for debit and credit processing to suit your needs and your customer
            demands.
            <br />
            <br />
            Our ecosystem supports full card-issuing product life cycle that
            enables you to launch products quickly.
            <br />
            <br />
            Our major card includes Traditional cards, Virtual cards for the
            major scheme.
          </p>
        </div>
        <div className="  py-[56px] w-1/2 lg:w-full p-[40px] ">
          <img
            src={woman2}
            alt=""
            width="456px"
            height="425px"
            className=" rounded-[8px] lg:w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default IssuerSolution;
