import React from "react";
import { useHistory } from "react-router-dom";
import Button from "./Button";

const PartnerWithUsBox = () => {
  const history = useHistory();

  return (
    <div className="w-full  bg-[#ffffff] px-[152px] py-[120px] lg:py-[60px] lg:px-[40px] md:px-[0px]">
      <div className="w-full  flex  lg:flex:col px-[132px] xl:px-[30px] bg-[#151917] rounded-[20px] md:rounded-[0px]">
        <div className="w-full py-[72px] mx-[131px] lg:mx-auto flex flex-col justify-center align-center items-center md:items-start">
          <h1 className="mb-[24px] text-[#ffffff] font-bold text-[36px] md:text-[26px] leading-[44px] md:leading-[33px] tracking-[0.025em]">
            Partner with us
          </h1>
          <p className="mb-[40px]  text-center md:text-left text-[#BBC4BF] font-bold text-[18px] leading-[26px] tracking-[0.01em] ">
            We strive to make payment easy. With partners anything is possible.
            Our way of working and our business models is based on your growth
            plan because together we work to make payment easy with our service.
          </p>
          <div className="flex justify-center md:justify-start ">
            <div
              onClick={() => {
                history.push("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              <Button className="bg-[#018038] h-[56px] min-w-[239px] flex text-[#ffffff] justify-center items-center border-none font-bold  p-[20px]">
                Contact our team
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUsBox;
