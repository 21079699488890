import React from "react";
import { Link } from "react-router-dom";

function Overlay0ne() {
  return (
    <div className="max-w-[1140px] mx-auto mt-[72px] ">
      <div className="w-full bg-white border border-solid sticky top-[72px] left-[152px] lg:top-[64px] opacity-[100%]">
        <div className="grid grid-cols-4 gap-x-[68px] lg:grid-flow-row md:grid-cols-2 lg:gap-y-10 px-[63px] py-[56px] ">
          <div className="mx-auto md:col-span-2 flex items-center flex-col text-center">
            <h4 className=" mb-[28px] text-[#1E2C24] font-[sarabun] font-medium text-[28px] leading-[36px] tracking-[0.01em] ">
              About US
            </h4>
            <li className="font-[sarabun] text-left font-normal text-[14px] leading-[20px] text-[#535F58] tracking-[0.01em]"></li>
          </div>
          <div className="md:col-span-2 ">
            <Link to="/about">
              <li className="mb-[20px] text-[16px] text-[#008037] font-semibold  leading-[24px]  hover:text-purple-700 tracking-[0.05em] ">
                Overview
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                Overview
              </p>
            </Link>
          </div>
          <div className="md:col-span-2 ">
            <Link to="/aboutcareers">
              <li className="mb-[20px] text-[16px] text-[#008037] font-semibold  leading-[24px]  hover:text-purple-700 tracking-[0.05em]">
                Careers
              </li>
              <p className="mb-[20px] text-[14px] text-[#535F58] font-semibold  leading-[20px] tracking-[0.01em] ">
                {" "}
                SaySwitch Careers
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overlay0ne;
