import React, { useState } from "react";
import Logo from "../assets/images/sayswitchlogo.svg";
import Logo2 from "../assets/images/hlockupWhite.png";
import Keydown from "../assets/icons/Keyboard arrow down.svg";
import Keydown2 from "../assets/icons/VectorWhite.png";
import logo from "../assets/icons/logo.svg";
import hamburger from "../assets/icons/icon-hamburger.svg";
import hamburger2 from "../assets/icons/barsWhite.svg";
import close from "../assets/icons/icon-close.svg";
import close2 from "../assets/icons/closeWhite.svg";
import arrowright from "../assets/icons/chevron-forward-outline (2).svg";
import arrowback from "../assets/icons/chevron-back-outline (2).svg";
import { Link, useHistory } from "react-router-dom";
import Button from "./Button";
import SubMenuOne from "./SubMenuOne";
import SubMenuTwo from "./SubMenuTwo";

const Navbar = ({ isLabel, setIsLabel, locPath }) => {
  const [navDisplay, setNavDisplay] = useState(false);
  const [mobileDisplay, setMobileDisplay] = useState("");
  const history = useHistory();

  const isLabelHandler = (identifier) => {
    if (isLabel === identifier) {
      setIsLabel("");
    } else {
      setIsLabel(identifier);
    }
  };

  const clearHandler = () => {
    setNavDisplay(false);
    setMobileDisplay("");
  };
  return (
    <div className="max-w-[1440px] scrollbar-hide mx-auto">
      <div
        className={`w-full h-[94px] lg:h-[64px] z-[1000] flex justify-between items-center px-[152px] lg:px-[70px] md:px-[30px] 
        ${
          locPath === "/about" ||
          locPath === "/pricing" ||
          locPath === "/about/"
            ? "bg-[#151917] text-[#ffffff]"
            : ""
        } 
        
       
        ${locPath === "/aboutcareers" ? "bg-[#F3F6F9]" : "bg-[#ffffff]"} `}
      >
        <div
          className={`flex  ${
            navDisplay ? "space-x-[32px] " : "space-x-[32px]"
          }`}
        >
          <div
            className={`flex items-center align-center lg:mb-[0px] mb-[10px] ${
              locPath === "/getstarted" ? "hidden" : ""
            }  `}
            onClick={() => history.push("/")}
          >
            <img
              className="select-none hidden md:block"
              src={logo}
              alt="logo"
              width="30px"
              height="30px"
            />

            {locPath === "/about" ||
            locPath === "/about/" ||
            locPath === "/pricing" ? (
              <img
                src={Logo2}
                alt=""
                className="block md:hidden w-[126px] h-[34.58px]"
              />
            ) : (
              <img
                src={Logo}
                alt=""
                className="block md:hidden w-[126px] h-[34.58px]"
              />
            )}
          </div>

          <div className="flex items-center space-x-[32px] xl:space-x-[15px] lg:hidden">
            <Link to="/">
              <li
                className={`font-archivomedium text-[16px] leading-[20px] tracking-[0.02em] font-medium cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600 ${
                  locPath === "/" ? "text-[#008037] " : ""
                } `}
              >
                Home
              </li>
            </Link>

            <li
              onClick={() => isLabelHandler("products")}
              className={`flex items-center font-archivomedium text-[16px] leading-[20px] tracking-[0.02em] font-medium cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600 ${
                locPath === "/about" ||
                locPath === "/about/" ||
                locPath === "/pricing"
                  ? "text-[#ffffff] "
                  : "text-[#0D1B27]"
              }`}
            >
              Products{" "}
              <span
                className={`ml-[2px] mt-[3px] ${
                  isLabel === "products" && "transition-all rotate-180"
                }`}
              >
                {locPath === "/about" ||
                locPath === "/about/" ||
                locPath === "/pricing" ? (
                  <img
                    src={Keydown2}
                    alt=""
                    className="w-[9.78px] h-[6.21px] ml-[5px] "
                  />
                ) : (
                  <img src={Keydown} alt="" width="20px" height="20px" />
                )}
              </span>
            </li>
            <Link to="/about">
              <li
                // onClick={() => isLabelHandler("about")}
                className={`flex items-center font-sarabun text-[16px] leading-[24px] tracking-[0.02em] font-medium cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600 
              ${
                locPath === "/about" ||
                locPath === "/about/" ||
                locPath === "/aboutcareers"
                  ? "text-[#008037] "
                  : ""
              } ${locPath === "/pricing" ? "text-[#ffffff] " : ""}`}
              >
                About{" "}
                <span className={` ${"transition-all rotate-180"}`}>
                  {/* {locPath === "/about" || locPath === "/pricing" ? (
                  <img
                    src={Keydown2}
                    alt=""
                    className="w-[9.78px] h-[6.21px] ml-[5px] "
                  />
                ) : (
                  <img src={Keydown} alt="" width="20px" height="20px" />
                )} */}
                </span>{" "}
              </li>
            </Link>

            <li
              className={`lg:hidden font-archivomedium text-[16px] leading-[20px] tracking-[0.02em] font-medium cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600 ${
                locPath === "/partners" ? "text-[#008037] " : ""
              }`}
            >
              <Link to="/partners">Partners</Link>
            </li>

            <Link to="/pricing">
              <li
                className={`lg:hidden font-archivomedium text-[16px] leading-[20px] tracking-[0.02em] font-medium cursor-pointer relative before:w-0 before:h-1 before:absolute before:top-[-15px] before:right-0 before:bg-green-600 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-green-600 ${
                  locPath === "/about" || locPath === "/about/"
                    ? "text-[#ffffff]"
                    : ""
                } ${locPath === "/pricing" ? "text-[#008037]  " : ""}`}
              >
                Pricing
              </li>
            </Link>
          </div>
        </div>
        <div
          className="hidden lg:block"
          onClick={() => setNavDisplay((prevDisplay) => !prevDisplay)}
        >
          {locPath === "/about" || locPath === "/about/" || locPath === "/pricing" ? (
            <img
              className="select-none fill-white"
              src={navDisplay ? close2 : hamburger2}
              alt="hamburger"
            />
          ) : (
            <img
              className="select-none fill-white"
              src={navDisplay ? close : hamburger}
              alt="hamburger"
            />
          )}
        </div>
        <div
          className={` flex flex-row  bg-hover:bg-green-100[#ffffff] lg:hidden `}
        >
          <a href="https://merchant.sayswitchgroup.com">
            <Button
              className={` w-[145px] h-[48px]  hover:text-[#008037] border-transparent ${
                locPath === "/about" ||
                locPath === "/about/" ||
                locPath === "/pricing"
                  ? "bg-[#151917] text-[#ffffff]"
                  : ""
              } `}
            >
              Log in
            </Button>
          </a>
          <Link to="/contactus">
            <Button className="hover:bg-green-600 border-none text-[#FFFFFF] bg-[#008037] w-[145px] h-[48px]">
              Contact us
            </Button>
          </Link>
        </div>
        {navDisplay && (
          <div className="bg-gray-600/30  backdrop-blur-sm w-full h-[100%] fixed left-0 top-[64px] z-[100]">
            <div className="mt-[20px] divide-y divide-slate-200 mx-auto grid text-[16px] text-[#000000] text-center gap-4 w-[90%] bg-white rounded-md font-medium py-6 px-10 relative">
              <div
                onClick={clearHandler}
                className=" select-none opacity-70  hover:opacity-90 "
              >
                <li className="flex mt-2 items-end">
                  <Link to="/">Home </Link>
                </li>
              </div>
              <div
                onClick={() => setMobileDisplay("About Us")}
                className="select-none opacity-70 hover:opacity-90"
              >
                <li className="flex mt-2 items-end">
                  <Link to="/about">About Us</Link>
                  <span
                    className={`ml-[5px] ${
                      isLabel === "About" && "transition-all rotate-180"
                    }`}
                  >
                    <img
                      src={arrowright}
                      alt=""
                      className="w-4 h-4 select-none"
                    />
                  </span>{" "}
                </li>
              </div>
              <div
                onClick={() => setMobileDisplay("Products")}
                className="select-none opacity-70 hover:opacity-90"
              >
                <li className="flex mt-2 items-end ">
                  <Link to="/products">What We Do - Products</Link>
                  <span
                    className={`ml-[5px]  ${
                      isLabel === "about" && "transition-all  rotate-180"
                    }`}
                  >
                    <img
                      src={arrowright}
                      alt=""
                      className="w-4 h-4 select-none"
                    />
                  </span>{" "}
                </li>
              </div>
              <div
                className="select-none opacity-70 hover:opacity-90"
                onClick={clearHandler}
              >
                <li className="flex mt-2 items-end">
                  <Link to="/partners">Partners</Link>
                </li>
              </div>
              <div
                className="select-none opacity-70 hover:opacity-90"
                onClick={clearHandler}
              >
                <li className="flex mt-2 items-end">
                  <Link to="/pricing">Pricing</Link>
                </li>
              </div>

              {mobileDisplay !== "" ? (
                <div className="absolute top-0 left-0 w-full min-h-[200px] z-200 bg-white flex px-10 py-5">
                  <div
                    onClick={() => setMobileDisplay("")}
                    className="flex flex-col text-left"
                  >
                    {" "}
                    <div className="flex flex-row mb-4">
                      <span className="mr-2 flex items-center">
                        <img
                          src={arrowback}
                          alt=""
                          className="w-4 h-4 text-black"
                        />
                      </span>
                      <h2 className="text-black text-2xl font-extrabold">
                        {mobileDisplay}
                      </h2>
                    </div>
                    <div className="">
                      {mobileDisplay === "About Us" ? (
                        <SubMenuTwo clearHandler={clearHandler} />
                      ) : (
                        <SubMenuOne clearHandler={clearHandler} />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
