import React from "react";
import logo from "../assets/images/div.png";
import frame1 from "../assets/images/Frame 115480.png";
import frame2 from "../assets/images/Frame 115483.png";
import frame3 from "../assets/images/Frame 115481.png";
import frame4 from "../assets/images/Frame 115482.png";

const BankingDoneBetter = () => {
  return (
    <div className="w-full pt-[120px] pb-[156px] flex flex-col px-[152px] lg:px-[70px] md:px-[30px] bg-[#F7F9FC]">
      <div className="w-full mb-[120px] lg:mb-[60px]">
        <div className="flex justify-center md:justify-start mb-[20px]">
          <img src={logo} alt="" className="h-[60px] w-[230.05px]" />
        </div>
      </div>
      <div className="flex gap-[40px] lg:w-full lg:flex-col ">
        <div className="w-[60%] lg:w-full md:flex-col md:items-center flex lg:justify-center gap-[42px]">
          <div className="flex flex-col gap-[42px] my-auto">
            <img src={frame1} alt="" className="h-[380px] w-[306px]" />
            <img src={frame2} alt="" className="h-[262px] w-[306px]" />
          </div>
          <div className="flex flex-col gap-[42px]">
            <img src={frame3} alt="" className="h-[326px] w-[306px]" />
            <img src={frame4} alt="" className="h-[465px] w-[306px]" />
          </div>
        </div>
        <div className="w-[40%] lg:w-full flex flex-col items-start gap-[42px] my-auto">
          <p className="font-[Sarabun] text-[#3B443F] font-normal text-[18px] leading-[26px] ">
            Don’t just take our word for it
          </p>
          <h1 className="font-[Sarabun] text-[#1E2C24] font-bold text-[60px] md:text-[40px] leading-[72px] md:leading-[50px]">
            Get started
            <br /> and make your payments with ease, wherever you are
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BankingDoneBetter;
