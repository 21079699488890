import React from "react";
import Frame from "../assets/images/Vector 1.png";
import icon from "../assets/icons/Frame 115515.png";
import icon2 from "../assets/icons/Frame 115526.png";
import icon3 from "../assets/icons/Frame 115518.png";
import icon4 from "../assets/icons/Frame 115524.png";

function OurBrand() {
  return (
    <div className="w-full px-[152px] mt-[-120px] pt-[152px] bg-[#FFFFFF] flex flex-col gap-[60px] lg:px-[40px] md:px-[0px] pb-[156px] lg:pb-[60px]">
      <div className="w-full  flex-wrap flex flex-col justify-center gap-[24px] items-center">
        <div className="w-full flex flex-col justify-center gap-[12px] items-center ">
          <img src={Frame} alt="" className="w-[41px]" />
          <p className="font-[Sarabun] font-bold text-[#2C2A1E] text-[18px] leading-[26px] tracking-[0.02em]">
            Our vision
          </p>
        </div>
        <div className="w-full  mb-[120px] lg:mb-[60px]">
          <h1 className="font-[Sarabun] px-[60px]  xl:px[0px] text-center md:text-left font-extrabold md:px-[30px] md:text-[40px] md:leading-[50px] text-[#1E2C24] text-[48px] leading-[56px] tracking-[0.025em]">
            We are passionate about
            <br /> payment experience
          </h1>
        </div>
        <div className="w-full flex md:flex-col lg:flex-wrap md:px-[30px] gap-[44px]">
          <div className="flex flex-col justify-start">
            <img
              src={icon}
              alt=""
              className="w-[48px] h-[48px] rounded-[3px] mb-[24px]"
            />
            <h5 className="mb-[8px] font-[Sarabun] text-[#1E2C24] font-bold text-[22px] leading-[30px] tracking-[0.01em]">
              We provide secured payment, peace of mind
            </h5>
            <p className="font-[Sarabun] text-[#1E2C24] font-normal text-[16px] leading-[24px] tracking-[0.01em]">
              We protect your data, your business reputation and bottom line
              with multi-layered fraud and cyber-crime security measures
            </p>
          </div>
          <div className="flex flex-col justify-start">
            <img
              src={icon2}
              alt=""
              className="w-[48px] h-[48px] rounded-[3px] mb-[24px]"
            />
            <h5 className="mb-[8px] font-[Sarabun] text-[#1E2C24] font-bold text-[22px] leading-[30px] tracking-[0.01em]">
              We enable business agility
            </h5>
            <p className="font-[Sarabun] text-[#1E2C24] font-normal text-[16px] leading-[24px] tracking-[0.01em]">
              We are on a mission to innovate. We are agile and unceasingly
              intense in our pursuit to make transactions seamless and
              convenient
            </p>
          </div>
          <div className="flex flex-col justify-start">
            <img
              src={icon3}
              alt=""
              className="w-[48px] h-[48px] rounded-[3px] mb-[24px]"
            />
            <h5 className="mb-[8px] font-[Sarabun] text-[#1E2C24] font-bold text-[22px] leading-[30px] tracking-[0.01em]">
              We are passionate about payment technology
            </h5>
            <p className="font-[Sarabun] text-[#1E2C24] font-normal text-[16px] leading-[24px] tracking-[0.01em]">
              We build our infrastructure around innovation. We are connecting
              Nigeria and Africa to the global payment ecosystem.
            </p>
          </div>
          <div className="flex flex-col justify-start">
            <img
              src={icon4}
              alt=""
              className="w-[48px] h-[48px] rounded-[3px] mb-[24px]"
            />
            <h5 className="mb-[8px] font-[Sarabun] text-[#1E2C24] font-bold text-[22px] leading-[30px] tracking-[0.01em]">
              We are committed to Excellence
            </h5>
            <p className="font-[Sarabun] text-[#1E2C24] font-normal text-[16px] leading-[24px] tracking-[0.01em]">
              We take ownership and commit to becoming better when new
              perspectives arise focusing on the bigger picture.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBrand;
