import React from "react";
import Photo from "../assets/images/friendly-polite-good-looking-african-american-girl-glasses-with-curly-hair-striped-office-blouse-pointing-left-with-finger-gun-grinning-with-joy-giving-advice-where-go 1.png";
import icon from "../assets/icons/ICONSpass.png";

const SayswitchExtra = () => {
  return (
    <div
      className="container  space-y-8 flex 
md:p-[30px] md:mt-[-20px] gap-12 flex-row 
md:flex-col-reverse md:items-center "
    >
      <div className="w-1/2 md:w-full my-auto space-y-[32px]">
        <h1 className="font-[sarabun] font-bold text-[36px] leading-[44px]">
          Sayswitch extras
        </h1>
        <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          Resources to support your growth, productivity and wellbeing;
        </p>
        <ul className="flex flex-col gap-[24px] font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            Top technology including internet access.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            Inspiring spaces to work, recharge and collaborate with others.
          </li>
        </ul>
      </div>
      <div className="w-1/2 md:w-full block flex justify-start">
        <img
          src={Photo}
          alt="pic"
          width="554px"
          height="556px"
          className="w-full  rounded-[10px]"
        />
      </div>
    </div>
  );
};

export default SayswitchExtra;
