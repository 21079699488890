import React from "react";

const PandCISOPolicy = () => {
  return (
    <>
      Sayswitch is committed to establishing effective information security,
      business continuity and service management plans to protect its key
      business assets, recover during disruption to business, provide quality
      and timely response to service requests and incidents as well as meet its
      obligations to its stakeholders. As part of this commitment, Sayswitch has
      established an Integrated Management System (IMS) consisting of
      Information Security, Business Continuity and Service Management which
      complies with the requirements of the ISO 27001:2013 ISO22301:2012 and ISO
      20000:2018 the international standards for information security, business
      continuity and service management.
      <ol className="ml-[18px]">
        <li className="list-disc my-[16px]  list-outside font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Sayswitch’s current strategy and Integrated Management System provides
          the context for identifying, assessing, evaluating, and controlling
          information/process/service-related risks through establishment and
          maintenance of the IMS. The risk assessment and risk treatment plan
          capture how identified risks are controlled in alignment with
          Sayswitch’s risk management strategy.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Business continuity and contingency plans, data backup procedures,
          access control to systems and information security incident reporting
          are fundamental to this policy. All employees of Sayswitch shall have
          the responsibility of reporting incidents.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          All employees of Sayswitch and external parties identified in the IMS
          are expected to comply with this policy. All staff and certain
          external parties will receive or be required to provide appropriate
          training.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          The IMS Manager is the owner of this document and is responsible for
          ensuring that this policy document is reviewed and approved by
          Management at least annually and in the event of relevant changes
          and/or incidents.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          A current version of this document is available to all members of
          staff on SharePoint. This policy is issued on a version-controlled
          basis under the signature of the Managing Director, Sayswitch.
        </li>
        <li className="list-disc list-outside mb-[16px] font-sarabunlight md:text-[14px] text-[16px] leading-[27px] tracking-wide">
          Breach of the policy or security mechanism may warrant disciplinary
          measures, up to and including termination of employment/contract as
          well as legal action in line with the Cybercrime Prohibition Act 2015.
        </li>
      </ol>
      Employees, suppliers or other stakeholders who observe any deviations to
      the guidelines of this Policy, may report the fact to the IMS Manager and
      may identify themselves or not.
    </>
  );
};

export default PandCISOPolicy;
