import React from "react";
import Photo from "../assets/images/photo-young-smiling-african-woman-has-video-call-night-while-sitting-bed-her-bedroom (3) 1.png";
import icon from "../assets/icons/ICONSpass.png";

const FlexibilityAndTimeOff = () => {
  return (
    <div
      className="container  space-y-8 flex 
md:p-[30px] md:mt-[-20px] gap-12 flex-row 
md:flex-col-reverse md:items-center "
    >
      <div className="w-1/2 md:w-full my-auto space-y-[32px]">
        <h1 className="font-[sarabun] font-bold text-[36px] leading-[44px]">
          Flexibilty and time off
        </h1>
        <p className="font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          The time to recharge and flexibility to get work done;
        </p>
        <ul className="flex flex-col gap-[24px] font-[Sarabun] font-normal text-[#3B443F] text-[16px] leading-[24px] tracking-[0.02em]">
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            Hybrid work model.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            Remote work opportunities.
          </li>
          <li className="flex gap-[14px]">
            <span>
              <img src={icon} alt="" className="min-w-[24px] h-[24px]" />
            </span>
            {""}
            Paid time off, including vacation, sick leave, and holidays
          </li>
        </ul>
      </div>
      <div className="w-1/2 md:w-full block flex justify-start">
        <img
          src={Photo}
          alt="pic"
          width="554px"
          height="556px"
          className="w-full  rounded-[10px]"
        />
      </div>
    </div>
  );
};

export default FlexibilityAndTimeOff;
